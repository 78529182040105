import React from "react";

const Allaitement: React.FC = () => (
  <>
    <p>Les méthodes contraceptives efficaces sont : </p>
    <ul>
      <li className="ion-margin-bottom">
        <span className="moyen-emph">La pilule progestative.</span> Le premier
        comprimé est à débuter classiquement{" "}
        <span className="moyen-emph">21 jours après votre accouchement</span>.
        (Voir “pilule progestative” pour plus d’informations)
      </li>
      <li className="ion-margin-bottom">
        <span className="moyen-emph">L’implant.</span> Il est posé{" "}
        <span className="moyen-emph">21 jours après votre accouchement</span>. A
        noter, que certaines maternité posent les implants dans les jours
        suivant votre accouchement. (Voir “implant” pour plus d’informations)
      </li>
      <li className="ion-margin-bottom">
        <span className="moyen-emph">
          Le DIU (Dispositif Intra Utérin) ou stérilet au cuivre ET hormonal.
        </span>
        Il peut être posé{" "}
        <span className="moyen-emph">
          4 semaines après un accouchement par les voies naturelles et 6
          semaines après une césarienne
        </span>
        . Il peut être posé par un médecin ou une sage-femme au moment de la
        consultation postnatale qui a lieu entre 6 et 8 semaines après
        l’accouchement. (Voir “DIU au cuivre” et “DIU hormonal” pour plus
        d’informations).
        <br />
        Si la pose du stérilet est prévue plus de 4 semaines après
        l’accouchement, un autre moyen de contraception en attendant la pose du
        stérilet doit être envisagé (pilule, préservatif).
      </li>
    </ul>
    <p>
      <span className="moyen-emph">Les préservatifs</span> peuvent être
      envisagés aussi. Ils ne sont efficaces qu’en cas d’utilisation parfaite.
      (Voir “préservatif masculin” et “préservatif féminin” pour plus
      d’informations)
    </p>
    <p>
      L’allaitement peut avoir un effet "contraceptif" mais cela nécessite de
      respecter des <span className="moyen-emph">règles rigoureuses</span> :
    </p>
    <ul>
      <li>Allaitement exclusif (pas de lait artificiel, pas d’eau)</li>
      <li>Allaitement à la demande jour et nuit</li>
      <li>Absence de règles depuis l’accouchement.</li>
    </ul>
    <p>
      Cette méthode est peu fiable. Si retomber enceinte rapidement n’est pas
      acceptable pour vous, il est recommandé d’opter pour une méthode
      contraceptive efficace.
    </p>
  </>
);

export default Allaitement;
