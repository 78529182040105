import { compact, concat, includes, uniq } from 'lodash';
import { ReponseActions } from '../actions/questionActions';
import { ReponseActionType, ReponseStateType, ReponseType } from "../actions/types";
import { GeneralAnswerType } from '../components/questions/GeneralQuestionType';
import { MultipleResponseType } from '../components/questions/MultipleQuestionType';
import { Moyen } from '../constants/moyen';
import { OptionType, QuestionType, zType } from '../data/questions';
import { getAge } from '../utils/age';
import { getIMC } from '../utils/imc';

export const questionReducer = (
  state: Array<QuestionType>,
  action: ReponseActions
) => {
  switch (action.type) {
    default:
      return state;
  }
};

const getPhrasesFor = (moyen: ReponseType, option: OptionType, reponse: MultipleResponseType): Array<Array<String>> => {
  const avantages = concat(option.coche?.avantages?.map(av => getPhrase(moyen, option.libelle, av, reponse.coche)),
    option.noncoche?.avantages?.map(av => getPhrase(moyen, option.libelle, av, reponse.noncoche)))
  const inconvenients = concat(
    option.coche?.inconvenients?.map(inc => getPhrase(moyen, option.libelle, inc, reponse.coche)),
    option.noncoche?.inconvenients?.map(inc => getPhrase(moyen, option.libelle, inc, reponse.noncoche))
  )

  return [compact(avantages), compact(inconvenients)]
}

const getPhrase = (moyen: ReponseType, optionLabel: String, avInc: zType, cNc: Array<String>): String => {
  if (avInc.moyens.includes(moyen.moyen.titre)) {
    for (const i of cNc) {
      if (i === optionLabel) {
        return avInc.phrase
      }
    }
  }
  return ""
}

export const reponseReducer = (
  state: ReponseStateType,
  action: ReponseActions
) => {
  switch (action.type) {
    case ReponseActionType.ANSWER_MULTIPLE:
    case ReponseActionType.ANSWER_RADIO:
      const newState = Object.assign({}, state)
      const { payload: { options, reponse: rep } } = action
      const { moyens } = newState

      for (const moyen of moyens) {
        for (const option of options) {
          const [av, inc] = getPhrasesFor(moyen, option, rep)
          moyen.avantages = uniq(concat(moyen.avantages, av))
          moyen.inconvenients = uniq(concat(moyen.inconvenients, inc))
        }
      }
      return newState
    case ReponseActionType.ANSWER_GENERALE:
      const { payload: { reponse } } = action
      const moy = updateWithGeneralQuestionType(state.moyens, reponse)

      return Object.assign({}, state, moy)
    case ReponseActionType.RESET:
      return []
    default:
      return state
  }
}


function updateWithGeneralQuestionType(moyens: ReponseType[], rep: GeneralAnswerType): ReponseType[] {
  const { taille, poids, dateDeNaissance, cigarette } = rep
  const age = getAge(dateDeNaissance)
  const imc = getIMC(taille, poids)

  if (age > 35) {
    for (const moyen of moyens) {
      if (includes([Moyen.ANNEAU, Moyen.PATCH, Moyen.PILULE_O], moyen.moyen.titre)) {
        if (imc > 25) {
          moyen.inconvenients = uniq(concat(moyen.inconvenients, "Existe une contre indication probable liée à votre poids et votre l'âge"))
        }
        if (cigarette) {
          moyen.inconvenients = uniq(concat(moyen.inconvenients, "Existe une contre-indication probable liée au tabac"))
        }
      }
    }
  }

  return moyens
}