import { IonRouterLink } from "@ionic/react";
import React from "react";
import appPages from "../../constants/pages";

const PiluleM: React.FC = () => (
  <>
    <p className="moyen-section">Présentation</p>
    <p>
      Cette pilule contient une hormone :{" "}
      <span className="moyen-emph">la progestérone</span>.<br />
      Sous forme de plaquettes de 28 comprimés sans aucune pause entre 2
      plaquettes.{" "}
    </p>
    <p className="moyen-section">Efficacité</p>
    <p>
      Quand elle est bien prise, elle est supérieure à{" "}
      <span className="moyen-emph">99.7%</span>. <br />
      Elle peut ne pas être efficace en cas:
    </p>
    <ul className="moyen-emph">
      <li>d'oubli</li>
      <li>de vomissement,</li>
      <li>de diarrhée</li>
      <li>de prise d'autres médicaments.</li>
    </ul>
    <p>
      Elle est efficace <span className="moyen-emph">après 48h de prise</span>{" "}
      si elle est débutée le premier jour des règles, sinon ce sera{" "}
      <span className="moyen-emph">au bout de 7 jours</span>.
    </p>
    <p className="moyen-danger">
      Il faut être prête à y penser tous les jours pour qu’elle soit efficace.
    </p>
    <p className="moyen-section">Comment l’obtenir ?</p>
    <p>
      Elle nécessite une <span className="moyen-emph">prescription</span> par un
      médecin ou une sage-femme. Elle est{" "}
      <span className="moyen-emph">remboursée à 65%</span>.
    </p>
    <p>
      Jusqu’à <span className="moyen-emph">26 ans</span>, certaines pilules sont
      délivrées{" "}
      <span className="moyen-emph">sans avance de frais et anonymement</span> :
      il n’est pas nécessaire de payer pour les obtenir et aucun autre
      justificatif qu’une ordonnance médicale n’est nécessaire.
    </p>
    <p>
      Elles peuvent aussi être obtenues dans les{" "}
      <span className="moyen-emph">centres de santé sexuelle</span> pour les
      mineures et les non-assurées sociales.
    </p>

    <p className="moyen-section">Les points positifs</p>
    <p>Elle permet de:</p>
    <ul className="moyen-emph">
      <li>diminuer les douleurs de règles</li>
      <li>de diminuer le flux des règles ou d’arrêter les règles</li>
    </ul>
    <p>
      Cette pilule peut avoir l’effet{" "}
      <span className="moyen-emph">d’arrêter complètement les règles</span>, ce
      qui peut être voulu.
    </p>
    <p>Elle peut être prise même si vous fumez.</p>
    <p>
      Certaines personnes à qui la pilule oestroprogestative est déconseillée ou
      interdite peuvent prendre la pilule progestative.
    </p>
    <p>Elle peut être prise pendant l’allaitement.</p>
    <p className="moyen-section">Les points négatifs</p>
    <p>Elle peut parfois:</p>
    <ul className="moyen-emph">
      <li>donner de petits saignements ponctuels (spotting)</li>
      <li>augmenter l'appétit</li>
      <li>donner des sautes d'humeur</li>
      <li>modifier le désir sexuel</li>
      <li>donner des douleurs aux seins</li>
      <li>maux de tête</li>
      <li>augmenter l'acné. </li>
    </ul>
    <p>
      Certains médicaments pris tous les jours peuvent diminuer son efficacité
      (en parler avec un professionnel de santé).
    </p>
    <p className="moyen-section">En cas d'oubli</p>
    <p>
      <span className="moyen-emph">
        Si vous avez oublié votre pilule il y a moins de 12h
      </span>{" "}
      (en comptant à partir de l’heure à laquelle vous auriez dû la prendre) ou
      moins de 3h (pour pilule au lévonorgestrel): prendre le comprimé oublié et
      continuer la plaquette normalement. On reste protégé.
    </p>
    <p>
      <span className="moyen-emph">
        Si vous avez oublié votre pilule il y a plus de 12h
      </span>{" "}
      (en comptant à partir de l’heure à laquelle vous auriez dû la prendre) ou
      plus de 3h (pour pilule au lévonorgestrel): prendre la pilule oubliée et
      prendre le prochain comprimé à l’heure habituelle (2 comprimés peuvent
      être pris le même jour). <br />
      <span className="moyen-color">
        Dans ce cas, vous n’êtes plus protégée
      </span>{" "}
      donc il faudra pendant les 7 jours suivant l’oubli, utiliser une
      contraception mécanique (les préservatifs).
    </p>
    <p>
      <span className="moyen-color">
        Si vous avez eu un rapport sexuel dans les 5 jours précédant l’oubli
      </span>
      , il faut prendre une contraception d’urgence. (Voir{" "}
      <IonRouterLink href={appPages.contraceptionUrgence.url}>
        "La contraception d’urgence"
      </IonRouterLink>
      )
    </p>
  </>
);

export default PiluleM;
