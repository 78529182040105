
import { Moyen } from '../../constants/moyen'; import { QuestionType } from '../questions';

export const question10: QuestionType = {
    id: 9,
    type: "multiple",
    question: "Quels effets secondaires aimeriez-vous éviter le plus ? (3 réponses maximum)",
    maxChoix: 3,
    options: [
        {
            libelle: "Petits saignements entre les règles (spotting)",
            coche: {
                inconvenients: [
                    {
                        moyens: [Moyen.IMPLANT, Moyen.STERILET_HORMONAL, Moyen.PILULE_M],
                        phrase: "Peut donner des petits saignement entre les règles (spotting)",
                    },
                ]
            }
        }, {
            libelle: "Augmentation de la quantité des règles (plus de sang perdu lors des règles)",
            coche: {
                inconvenients: [
                    {
                        moyens: [Moyen.STERILET_CUIVRE],
                        phrase: "Peut augmenter la quantité des règles",
                    }
                ]
            }
        }, {
            libelle: "Douleurs dans les seins",
            coche: {
                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.IMPLANT, Moyen.PATCH, Moyen.ANNEAU, Moyen.STERILET_HORMONAL],
                        phrase: "Peut donner des douleurs dans les seins",
                    }
                ]
            }
        }, {
            libelle: "Augmentation de l'appétit/Prise de poids",
            coche: {
                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.PATCH, Moyen.ANNEAU, Moyen.STERILET_HORMONAL],
                        phrase: "Peut augmenter l'appétit et faire prendre du poids",
                    }, {
                        moyens: [Moyen.IMPLANT],
                        phrase: "Peut augmenter l'appétit et faire prendre du poids surtout pour les femmes en surpoids",
                    }
                ]
            }
        }, {
            libelle: "Problèmes de peaux (type acné)",
            coche: {
                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.IMPLANT, Moyen.PATCH, Moyen.ANNEAU, Moyen.STERILET_HORMONAL],
                        phrase: "Peut augmenter les problèmes d'acné",
                    }
                ]
            }
        }, {
            libelle: "Sautes d’humeur",
            coche: {
                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.IMPLANT, Moyen.PATCH, Moyen.ANNEAU, Moyen.STERILET_HORMONAL],
                        phrase: "Peut donner des sautes d'humeur",
                    }
                ]
            }
        }, {
            libelle: "Modification du désir sexuel",
            coche: {
                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.IMPLANT, Moyen.PATCH, Moyen.ANNEAU, Moyen.STERILET_HORMONAL],
                        phrase: "Peut modifier le désir sexuel",
                    }
                ]
            }
        }, {
            libelle: "Aucun de ces effets en particulier",
            coche: {
            }
        }, {
            libelle: "Je ne sais pas",
            coche: {
            }
        }
    ]
}
