import {
  IonButton,
  IonCheckbox,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
} from "@ionic/react";
import { chevronForward } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { ReponseActionType } from "../../actions/types";
import { QuestionsContext } from "../../contexts/contexts";
import { QuestionType } from "../../data/questions";

type MultipleQuestionTypeProps = {
  slideTo: (slideId: number) => Promise<void>;
  question: QuestionType;
};

type MultipleResponseType = {
  coche: Array<String>;
  noncoche: Array<String>;
};

const MultipleQuestionType: React.FC<MultipleQuestionTypeProps> = ({
  slideTo,
  question,
}) => {
  const [values, setValues] = useState<MultipleResponseType>({
    coche: [],
    noncoche: [],
  });

  const { dispatch } = useContext(QuestionsContext);

  useEffect(() => {
    setValues({
      coche: [],
      noncoche: question.options?.map((option) => option.libelle),
    });
  }, [question]);

  const handleChange = (e: any) => {
    if (values?.coche.includes(e.target.name)) {
      setValues({
        coche: values.coche.filter((c) => c !== e.target.name),
        noncoche: values.noncoche.concat(e.target.name),
      });
    } else {
      setValues({
        coche: values.coche.concat(e.target.name),
        noncoche: values.noncoche.filter((nc) => nc !== e.target.name),
      });
    }
  };

  const disableCheckbox = (includes: boolean | undefined) =>
    !!includes
      ? false
      : (values?.coche.length || 0) >= (question.maxChoix || 100000);

  const slideToNext = () => {
    dispatch({
      type: ReponseActionType.ANSWER_MULTIPLE,
      payload: {
        index: question.id,
        reponse: values,
        options: question.options,
      },
    });
    slideTo(question.id + 1);
  };

  return (
    <>
      <IonList>
        {question.options.map((reponse, i) => (
          <IonItem key={`reponse-${i}`}>
            <IonLabel className="ion-text-wrap">{reponse.libelle}</IonLabel>
            <IonCheckbox
              slot="start"
              disabled={disableCheckbox(
                values?.coche.includes(reponse.libelle)
              )}
              onIonChange={handleChange}
              name={reponse.libelle}
              checked={values?.coche.includes(reponse.libelle)}
            />
          </IonItem>
        ))}
      </IonList>
      <IonButton
        onClick={slideToNext}
        style={{ margin: "40px 0px" }}
        disabled={values?.coche.length === 0}
      >
        Suivant
        <IonIcon slot="end" icon={chevronForward} />
      </IonButton>
    </>
  );
};

export type { MultipleResponseType };
export default MultipleQuestionType;
