
import { Moyen } from '../../constants/moyen'; import { QuestionType } from '../questions';

// TYPE : multiple
// MOYEN : PILULE_O,PILULE_M,PATCH,ANNEAU,IMPLANT,STERILET_HORMONAL,STERILET_CUIVRE,PRESERVATIF_M,PRESERVATIF_F,DIAPHRAGME,CAPE,NATURELLES,STERILISATION,PROGESTATIFS,CONTRACEPTION_URGENCE,SPERMICIDES

export const question21: QuestionType = {
    id: 20,
    type: "multiple",
    question: "Avez-vous un traitement parmi la liste ci-dessous ?",
    options: [
        {
            libelle: "Pour traiter de l'épilepsie",
            coche: {
                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.PILULE_M, Moyen.PATCH, Moyen.ANNEAU, Moyen.IMPLANT, Moyen.STERILET_HORMONAL],
                        phrase: "Interaction probable avec votre traitement",
                    }
                ]
            }
        }, {
            libelle: "Contre le VIH ou l'hépatite C",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.PILULE_M, Moyen.PATCH, Moyen.ANNEAU, Moyen.IMPLANT, Moyen.STERILET_HORMONAL],
                        phrase: "Interaction probable avec votre traitement",
                    }
                ]
            }
        }, {
            libelle: "Rifampicine ? Rifabutine ? (prévention de la tuberculose, méningite)",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.PILULE_M, Moyen.PATCH, Moyen.ANNEAU, Moyen.IMPLANT, Moyen.STERILET_HORMONAL],
                        phrase: "Interaction probable avec votre traitement",
                    }
                ]
            }
        }, {
            libelle: "Modafinil ? Modiodal ?(narcolepsie, somnolence excessive)",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.PILULE_M, Moyen.PATCH, Moyen.ANNEAU, Moyen.IMPLANT, Moyen.STERILET_HORMONAL],
                        phrase: "Interaction probable avec votre traitement",
                    }
                ]
            }
        }, {
            libelle: "Millepertuis",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.PILULE_M, Moyen.PATCH, Moyen.ANNEAU, Moyen.IMPLANT, Moyen.STERILET_HORMONAL],
                        phrase: "Interaction probable avec votre traitement",
                    }
                ]
            }
        }, {
            libelle: "Griséofulvine",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.PILULE_M, Moyen.PATCH, Moyen.ANNEAU, Moyen.IMPLANT, Moyen.STERILET_HORMONAL],
                        phrase: "Interaction probable avec votre traitement",
                    }
                ]
            }
        }, {
            libelle: "Aucun",
            coche: {}
        }
    ]
}
