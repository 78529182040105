import { IonCol, IonContent, IonGrid, IonRow } from "@ionic/react";
import { isEmpty } from "lodash";
import React from "react";
import {} from "../../actions/types";
import { QuestionType } from "../../data/questions";
import GeneralQuestionType from "./GeneralQuestionType";
import MultipleQuestionType from "./MultipleQuestionType";
import RadioQuestionType from "./RadioQuestionType";

type SlideContentProps = {
  key: string;
  slideTo: (slideId: number) => Promise<void>;
  question: QuestionType;
};

const SlideContent: React.FC<SlideContentProps> = ({ slideTo, question }) => {
  return (
    <IonContent>
      <IonGrid>
        {!isEmpty(question.question) && (
          <IonRow>
            <IonCol>
              <h1>{question.question}</h1>
              {question.maxChoix && (
                <h5>Nombre de choix maximum : {question.maxChoix}</h5>
              )}
            </IonCol>
          </IonRow>
        )}
        <IonRow>
          <IonCol>
            {question.type === "radio" && (
              <RadioQuestionType question={question} slideTo={slideTo} />
            )}
            {question.type === "multiple" && (
              <MultipleQuestionType question={question} slideTo={slideTo} />
            )}
            {question.type === "generale" && (
              <GeneralQuestionType question={question} slideTo={slideTo} />
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default SlideContent;
