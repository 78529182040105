import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonMenuButton,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import cu from "../assets/images/Contraception urgence.jpg";
import st from "../assets/images/Stérilet.png";
import "../components/moyenModal/moyens.css";
import ContraceptionUrgenceHormonale from "../components/moyens/ContraceptionUrgenceHormonale";
import SteriletCuivreContraceptionUrgence from "../components/moyens/SteriletCuivreContraceptionUrgence";
import { Moyen } from "../constants/moyen";
import useModal from "../hooks/useModal";

const ContraceptionUrgence: React.FC = () => {
  const [firstModalShowing, showFirstModal, hideFirstModal] = useModal();
  const [secondModalShowing, showSecondModal, hideSecondModal] = useModal();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>La contraception d'urgence</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding-horizontal">
        <p className="moyen-section">
          Que faire si on a oublié sa pilule, si le préservatif se déchire ou si
          vous avez un rapport sans contraception ?
        </p>
        <p>
          Si vous vous retrouvez dans une situation pouvant finir par une
          grossesse non prévue,{" "}
          <span className="moyen-emph">des solutions existent</span>. Plus elles
          sont
          <span className="moyen-emph"> réalisées tôt</span>, moins il y a de
          chance de tomber enceinte.
        </p>
        <p>Il existe deux types de contraception d’urgence :</p>
        <ul className="moyen-emph">
          <li>La contraception d’urgence hormonale dite pilule du lendemain</li>
          <li>La pose d’un stérilet intra-utérin au cuivre.</li>
        </ul>
        <p>
          Attention, aucune contraception d’urgence permet d’annuler le risque à
          100%.
        </p>
        <p>
          Ne pas oublier qu’un rapport à risque, cela peut apporter aussi des
          maladies appelées infections sexuellement transmissibles (IST). Il
          faut donc{" "}
          <span className="moyen-emph">penser à se faire dépister</span>.
        </p>

        <IonGrid>
          <IonRow class="ion-align-items-center">
            <IonCol size="6">
              <IonCard onClick={showFirstModal}>
                <IonImg src={cu} />
                <IonCardHeader>
                  <IonCardTitle>La pilule d'urgence</IonCardTitle>
                </IonCardHeader>
              </IonCard>
              <IonModal
                isOpen={firstModalShowing}
                onWillDismiss={hideFirstModal}
              >
                <IonHeader>
                  <IonToolbar>
                    <IonButtons slot="start">
                      <IonButton onClick={hideFirstModal}>
                        <IonIcon icon="close" />
                      </IonButton>
                    </IonButtons>
                    <IonTitle>Pilule d'urgence</IonTitle>
                  </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                  <ContraceptionUrgenceHormonale />
                </IonContent>
              </IonModal>
            </IonCol>
            <IonCol size="6">
              <IonCard onClick={showSecondModal}>
                <IonImg src={st} />
                <IonCardHeader>
                  <IonCardTitle>{Moyen.STERILET_CUIVRE}</IonCardTitle>
                </IonCardHeader>
              </IonCard>
              <IonModal
                isOpen={secondModalShowing}
                onWillDismiss={hideSecondModal}
              >
                <IonHeader>
                  <IonToolbar>
                    <IonButtons slot="start">
                      <IonButton onClick={hideSecondModal}>
                        <IonIcon icon="close" />
                      </IonButton>
                    </IonButtons>
                    <IonTitle>{Moyen.STERILET_CUIVRE}</IonTitle>
                  </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                  <SteriletCuivreContraceptionUrgence />
                </IonContent>
              </IonModal>
            </IonCol>
          </IonRow>
        </IonGrid>
        <div style={{ margin: "132px 0px" }} />
      </IonContent>
    </IonPage>
  );
};

export default ContraceptionUrgence;
