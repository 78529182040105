import { MoyenType } from "../actions/types";
import anneau from "../assets/images/Anneau contraceptif.jpg";
import cape from "../assets/images/Cape_diaphragme.jpg";
import implant from "../assets/images/Implant.jpg";
import ic from "../assets/images/Injection contraceptive.jpg";
import lt from "../assets/images/Ligature trompe.jpg";
import mn from "../assets/images/Méthode naturelle.jpg";
import pc from "../assets/images/Patch contraceptif.png";
import po from "../assets/images/Pilule oestro progestative.jpg";
import pp from "../assets/images/pilule progestative.png";
import pf from "../assets/images/préservatif féminin.jpg";
import pm from "../assets/images/Préservatif masculin.png";
import sp from "../assets/images/Spermicide.jpg";
import st from "../assets/images/Stérilet.png";
import Anneau from '../components/moyens/Anneau';
import Cape from '../components/moyens/Cape';
import Diaphragme from '../components/moyens/Diaphragme';
import Implant from '../components/moyens/Implant';
import Naturelles from '../components/moyens/Naturelles';
import Patch from '../components/moyens/Patch';
import PiluleM from '../components/moyens/PiluleM';
import PiluleO from '../components/moyens/PiluleO';
import PreservatifF from '../components/moyens/PreservatifF';
import PreservatifM from '../components/moyens/PreservatifM';
import Progestatif from '../components/moyens/Progestatif';
import Spermicides from '../components/moyens/Spermicides';
import SteriletCuivre from '../components/moyens/SteriletCuivre';
import SteriletHormonal from '../components/moyens/SteriletHormonal';
import Sterilisation from '../components/moyens/Sterilisation';
import { Moyen, MoyenNonClasse } from '../constants/moyen';

const moyens: Array<MoyenType> = [
  {
    titre: Moyen.PILULE_O,
    img: po,
    composant: PiluleO,
    indice: 91,
    displayInResult: true,
  },
  {
    titre: Moyen.PILULE_M,
    img: pp,
    composant: PiluleM,
    indice: 91,
    displayInResult: true,
  },
  {
    titre: Moyen.PATCH,
    img: pc,
    composant: Patch,
    indice: 91,
    displayInResult: true,
  },
  {
    titre: Moyen.ANNEAU,
    img: anneau,
    composant: Anneau,
    indice: 92,
    displayInResult: true,
  }, {
    titre: Moyen.IMPLANT,
    img: implant,
    composant: Implant,
    indice: 99.9,
    displayInResult: true,
  },
  {
    titre: Moyen.STERILET_HORMONAL,
    img: st,
    composant: SteriletHormonal,
    indice: 99.8,
    displayInResult: true,
  },
  {
    titre: Moyen.STERILET_CUIVRE,
    img: st,
    composant: SteriletCuivre,
    indice: 99.2,
    displayInResult: true,
  },
  {
    titre: MoyenNonClasse.PROGESTATIFS,
    img: ic,
    composant: Progestatif,
    indice: 50,
    displayInResult: true,
  },
  {
    titre: Moyen.PRESERVATIF_M,
    img: pm,
    composant: PreservatifM,
    indice: 85,
    displayInResult: true,
  },
  {
    titre: Moyen.PRESERVATIF_F,
    img: pf,
    composant: PreservatifF,
    indice: 79,
    displayInResult: true,
  }, {
    titre: Moyen.CAPE,
    img: cape,
    composant: Cape,
    indice: 84,
    displayInResult: true,
  },
  {
    titre: Moyen.DIAPHRAGME,
    img: cape,
    composant: Diaphragme,
    indice: 88,
    displayInResult: true,
  },
  {
    titre: MoyenNonClasse.SPERMICIDES,
    img: sp,
    composant: Spermicides,
    indice: 71,
    displayInResult: false,
  },
  {
    titre: Moyen.NATURELLES,
    img: mn,
    composant: Naturelles,
    indice: 78,
    displayInResult: true,
  },
  {
    titre: Moyen.STERILISATION,
    img: lt,
    composant: Sterilisation,
    indice: 99.5,
    displayInResult: true,
  }
];

export default moyens;
