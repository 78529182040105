import React from "react";

const PreservatifM: React.FC = () => (
  <>
    <p className="moyen-section">Présentation</p>
    <p>
      Le préservatif externe (masculin) empêche le passage des spermatozoïdes
      dans le vagin, et donc la fécondation. C’est une{" "}
      <span className="moyen-emph">gaine</span> en latex ou polyuréthane (en cas
      d’allergie au latex) qui retient le sperme et empèche le contact direct
      avec le vagin de la femme. Il se{" "}
      <span className="moyen-emph">
        déroule sur le pénis en érection avant la pénétration
      </span>
      . Puis il s’enlève, avant la fin de l’érection, en retenant le préservatif
      à la base du pénis. L’homme doit se retirer juste après éjaculation.
    </p>
    <p>
      Il est le seul moyen de contraception avec le préservatif féminin{" "}
      <span className="moyen-emph">
        à protéger contre les infections sexuellement transmissibles (IST)
      </span>
      .
    </p>
    <p>
      Il peut être utilisé en complément d’une autre méthode de contraception
      (double protection contraceptive et protection contre les IST).
    </p>
    <p>
      Il doit être changé à{" "}
      <span className="moyen-emph">chaque rapport sexuel</span>, entre chaque
      pénétration.
    </p>
    <p className="moyen-section">Efficacité</p>
    <p>
      Avec les risques de mauvaise utilisation, le glissement de préservatif,
      les ruptures, son efficacité pratique est de{" "}
      <span className="moyen-emph">85%</span>. En cas d’utilisation parfaite,
      son efficacité est de 98%.
    </p>
    <p>
      Il n’est efficace que si on l’utilise pour TOUS les rapports sexuels,
      avant toute pénétration, à TOUT moment du cycle.
    </p>
    <p>Attention de :</p>
    <ul className="moyen-emph">
      <li>Ne pas mettre 2 préservatifs l’un sur l’autre. </li>
      <li>
        Ne pas utiliser d’huile, de beurre ou de vaseline pour lubrifier
        (fragilisent le préservatif), un gel lubrifiant peut cependant être
        utilisé.
      </li>
      <li>Ne pas utiliser en même temps qu’un préservatif féminin.</li>
      <li>Choisir un préservatif à sa taille.</li>
      <li>
        Choisir une boîte de préservatif avec le sigle CE (Communauté
        Européenne) ou NF (Norme Française).
      </li>
      <li>vérifier la date de péremption</li>
    </ul>
    <p className="moyen-section">Comment l'obtenir ?</p>
    <p>
      Il s’achète <span className="moyen-emph">sans ordonnance</span> et
      facilement (supermarché, café-tabac, distributeurs, internet, pharmacie...
      ). <span className="moyen-emph">Avec une ordonnance</span>, certains
      préservatifs (comme la marque "EDEN") sont{" "}
      <span className="moyen-emph">remboursés</span>. Sinon son prix est de 50 à
      70 centimes par préservatif. Des endroits comme les centres de santé
      sexuelle, les centres gratuits d’information de dépistage et de diagnostic
      (CeGIDD) ou les associations de lutte contre le VIH en donnent
      gratuitement.
    </p>
  </>
);

export default PreservatifM;
