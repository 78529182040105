import {
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonThumbnail,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import React from "react";
import { useLocation } from "react-router-dom";
import logo from "../../assets/images/My.t.png";
import appPages from "../../constants/pages";
import "./Menu.css";

const Menu: React.FC = () => {
  const location = useLocation();

  return (
    <IonMenu contentId="main" type="overlay">
      <IonHeader>
        <IonToolbar>
          <IonThumbnail slot="start">
            <IonImg src={logo} />
          </IonThumbnail>
          <IonTitle className="menu-title">My Choice</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList id="inbox-list">
          {Object.values(appPages)
            .filter((appPage) => appPage.menuDisplay)
            .map((appPage, index) => (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? "selected" : ""
                  }
                  routerLink={appPage.url}
                  routerDirection="root"
                  lines="none"
                  detail={false}
                >
                  <IonIcon
                    slot="start"
                    ios={appPage.iosIcon}
                    md={appPage.mdIcon}
                  />
                  <IonLabel className="ion-text-wrap">{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            ))}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
