import {
  IonButton,
  IonCol,
  IonDatetime,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonRow,
} from "@ionic/react";
import { chevronForward } from "ionicons/icons";
import React, { useContext, useState } from "react";
import { ReponseActionType } from "../../actions/types";
import { QuestionsContext } from "../../contexts/contexts";
import { QuestionType } from "../../data/questions";

type GeneralQuestionTypeProps = {
  slideTo: (slideId: number) => Promise<void>;
  question: QuestionType;
};

type GeneralAnswerType = {
  dateDeNaissance: string;
  taille: number;
  poids: number;
  cigarette: boolean;
};

const months = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Aout",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

const GeneralQuestionType: React.FC<GeneralQuestionTypeProps> = ({
  question,
  slideTo,
}) => {
  const [dateDeNaissance, setDate] = useState<string>();
  const [poids, setPoids] = useState<number>();
  const [taille, setTaille] = useState<number>();
  const [cigarette, setCigarette] = useState<boolean>(false);
  const { dispatch } = useContext(QuestionsContext);

  const handleChange = (setStateFun: Function) => (e: any) =>
    setStateFun(e.detail.value);

  const slideToNext = () => {
    dispatch({
      type: ReponseActionType.ANSWER_GENERALE,
      payload: {
        index: question.id,
        reponse: { dateDeNaissance, taille, poids, cigarette },
      },
    });
    slideTo(question.id + 1);
  };

  return (
    <IonGrid>
      <IonRow>
        <IonCol>
          <h1>Quelle est votre date de naissance ?</h1>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonItem>
            <IonLabel>Choisir une date</IonLabel>
            <IonDatetime
              displayFormat="MMMM YYYY"
              doneText="Valider"
              cancelText="Annuler"
              monthNames={months}
              value={dateDeNaissance}
              onIonChange={handleChange(setDate)}
            />
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <h1>Quelle est votre poids ?</h1>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonInput
            type="number"
            autofocus
            inputmode="numeric"
            value={poids}
            min={"30"}
            max={"300"}
            placeholder="Entrer votre poids en kg"
            onIonChange={handleChange(setPoids)}
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <h1>Quelle est votre taille ?</h1>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonInput
            type="number"
            autofocus
            inputmode="numeric"
            value={taille}
            min={"120"}
            max={"250"}
            placeholder="Entrer votre taille en cm"
            onIonChange={handleChange(setTaille)}
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <h1>Est ce que vous fumez ?</h1>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonRadioGroup
            onIonChange={handleChange(setCigarette)}
            value={cigarette}
          >
            <IonItem>
              <IonLabel className="ion-text-wrap">
                Oui (même ponctuellement)
              </IonLabel>
              <IonRadio slot="start" value={true} />
            </IonItem>
            <IonItem>
              <IonLabel className="ion-text-wrap">Non</IonLabel>
              <IonRadio slot="start" value={false} />
            </IonItem>
          </IonRadioGroup>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonButton
            onClick={slideToNext}
            style={{ margin: "32px 0px" }}
            disabled={!dateDeNaissance || !taille || !poids}
          >
            Suivant
            <IonIcon slot="end" icon={chevronForward} />
          </IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default GeneralQuestionType;
export type { GeneralAnswerType };
