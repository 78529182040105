
import { Moyen } from '../../constants/moyen'; import { QuestionType } from '../questions';

export const question11: QuestionType = {
    id: 10,
    type: "radio",
    question: "Considérez-vous comme un avantage de ne plus avoir ses règles ?",
    options: [
        {
            libelle: "Oui",
            coche: {
                avantages: [
                    {
                        moyens: [Moyen.STERILET_HORMONAL, Moyen.PILULE_M, Moyen.IMPLANT],
                        phrase: "Peut arrêter les règles",
                    }
                ],
                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.STERILET_CUIVRE, Moyen.PRESERVATIF_F, Moyen.PRESERVATIF_M, Moyen.CAPE, Moyen.DIAPHRAGME, Moyen.NATURELLES, Moyen.STERILISATION, Moyen.ANNEAU, Moyen.PATCH],
                        phrase: "N'arrête pas les règles",
                    }
                ]
            }
        }, {
            libelle: "Non, je souhaite garder mes règles",
            coche: {
                avantages: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.STERILET_CUIVRE, Moyen.PRESERVATIF_F, Moyen.PRESERVATIF_M, Moyen.CAPE, Moyen.DIAPHRAGME, Moyen.NATURELLES, Moyen.STERILISATION, Moyen.ANNEAU, Moyen.PATCH],
                        phrase: "N'arrête pas les règles",
                    }
                ],
                inconvenients: [
                    {
                        moyens: [Moyen.STERILET_HORMONAL, Moyen.PILULE_M, Moyen.IMPLANT],
                        phrase: "Peut arrêter les règles",
                    }
                ]
            }
        }, {
            libelle: "Je ne le considère pas comme un avantage mais cela ne me dérangerait pas de ne plus avoir mes règles",
            coche: {
            }
        }, {
            libelle: "Je ne sais pas/Je n'ai pas d'avis sur ce sujet",
            coche: {
            }
        }
    ]
}
