import { Moyen } from '../../constants/moyen'; import { QuestionType } from '../questions';

export const question3: QuestionType = {
    id: 2,
    type: "radio",
    question: "Avez-vous déjà eu des enfants ?",
    options: [
        {
            libelle: "Oui",
            coche: {
                avantages: [
                    {
                        moyens: [Moyen.CAPE],
                        phrase: "Moins efficace du fait des modifications physiques liés à la grossesse"
                    }
                ]
            }
        },
        {
            libelle: "Non",
            coche: {
                goTo: { questionIndex: 6 }
            }
        }
    ],
}