import React from "react";

const Spermicides: React.FC = () => (
  <>
    <p className="moyen-section">Présentation</p>
    <p>
      Les spermicides contiennent une substance que l’on utilise{" "}
      <span className="moyen-emph">au moment d’un rapport sexuel</span> pour
      détruire ou rendre inefficace les spermatozoïdes. On les retrouve sous
      forme de crème, de gel ou d’ovule à insérer dans le vagin.
    </p>
    <p>
      Un ovule de spermicide doit être placé au fond du vagin 10 minutes au
      minimum avant un rapport (pour qu’il fonde dans le vagin). Sa durée
      d’action est de 60 minutes.
    </p>
    <p>Les crèmes ont une durée d’action de 8 heures. </p>
    <p>
      Par contre, si vous vous lavez à l’aide de savon et d’eau après l’avoir
      mis, il sera inactivé. Si vous êtes en cours de traitement par des ovules
      ou crèmes vaginales, le spermicide sera moins efficace.
    </p>
    <p>
      Ils ne sont pas irritants sauf en cas d’utilisation très fréquente
      (plusieurs fois par jour). Il ne faut pas les utiliser en cas d’infection
      vaginale, mycose, infections urinaires à répétition ou en présence d’une
      plaie vaginale.
    </p>
    <p>
      Il ne faut pas les utiliser en cas d’infection vaginale, mycose,
      infections urinaires à répétition ou en présence d’une plaie vaginale.
    </p>
    <p className="moyen-section">Efficacité</p>
    <p>
      Ils sont <span className="moyen-emph">peu efficaces seuls</span> donc ils
      sont souvent utilisés avec un autre moyen de contraception dit “barrière”
      comme les préservatifs, le diaphragme ou la cape cervicale.
    </p>
    <p>
      <span className="moyen-emph">Plus de 2 femmes sur 10</span> (22%) tombent
      enceinte en utilisant seulement les spermicides.{" "}
    </p>
    <p className="moyen-section">Comment l'obtenir ?</p>
    <p>
      Ils peuvent s’acheter <span className="moyen-emph">sans ordonnance</span>.
      Ils coûtent entre 5 à 20 euros et ne sont{" "}
      <span className="moyen-emph">pas remboursés</span>.
    </p>
  </>
);

export default Spermicides;
