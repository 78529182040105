import { Moyen, MoyenNonClasse } from '../constants/moyen'
import { question1 } from './questions/question1';
import { question10 } from './questions/question10';
import { question11 } from './questions/question11';
import { question12 } from './questions/question12';
import { question13 } from './questions/question13';
import { question14 } from './questions/question14';
import { question15 } from './questions/question15';
import { question16 } from './questions/question16';
import { question17 } from './questions/question17';
import { question18 } from './questions/question18';
import { question19 } from './questions/question19';
import { question2 } from './questions/question2';
import { question20 } from './questions/question20';
import { question21 } from './questions/question21';
import { question3 } from './questions/question3';
import { question4 } from './questions/question4';
import { question5 } from './questions/question5';
import { question6 } from './questions/question6';
import { question7 } from './questions/question7';
import { question8 } from './questions/question8';
import { question9 } from './questions/question9';

interface ICochePhrase {
    moyens: Array<Moyen | MoyenNonClasse>,
    phrase: string;
}

interface GoToType {
    questionIndex: number;
}

interface ICoche {
    avantages?: Array<ICochePhrase>;
    inconvenients?: Array<ICochePhrase>;
    goTo?: GoToType;
}

interface IOption {
    libelle: string;
    coche?: ICoche;
    noncoche?: ICoche;
}

interface IQuestion {
    id: number;
    type: string,
    question: string;
    options: Array<IOption>;
    maxChoix?: number;
    min?: string;
    max?: string;
}

export const questions: Array<IQuestion> = [
    question1,
    question2,
    question3,
    question4,
    question5,
    question6,
    question7,
    question8,
    question9,
    question10,
    question11,
    question12,
    question13,
    question14,
    question15,
    question16,
    question17,
    question18,
    question19,
    question20,
    question21,
]

export type { IQuestion as QuestionType, IOption as OptionType, ICoche as CocheType, ICochePhrase as zType, GoToType }
