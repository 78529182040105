import React from "react";

const Naturelles: React.FC = () => (
  <>
    <p>
      Les méthodes dites naturelles sont les méthodes qui visent{" "}
      <span className="moyen-emph">à identifier la période d’ovulation</span>{" "}
      donc la période où le risque de tomber enceinte est le plus grand.
    </p>
    <p>
      <span className="moyen-emph">1 femme sur 4</span> tombe enceinte avec ces
      méthodes.
    </p>
    <p>
      Si les cycles sont irréguliers (nombre de jours entre chaque période de
      règles différents), le risque de tomber enceinte est encore plus grand.
    </p>
    <p>
      La période d’ovulation peut être influencée (par les émotions par exemple)
      et donc ne pas être au milieu d’un cycle).
    </p>
    <p>
      Il faut être organisé et coopérer avec son partenaire. Les femmes
      utilisant cette méthode ne doivent{" "}
      <span className="moyen-emph">
        pas avoir de rapport sexuel à certaines périodes du mois
      </span>
      .
    </p>
    <p className="moyen-section">L’abstinence périodique</p>
    <p>
      Cela consiste à calculer sa{" "}
      <span className="moyen-emph">période de "fertilité"</span> grâce au cycle
      menstruel. La période de “fertilité” est le moment où une femme a le plus
      de chance de tomber enceinte.
    </p>
    <p>
      Un cycle est le nombre de jours entre le premier jour des règles et le
      jour d’avant le premier jour des prochaines règles. Il est différent selon
      les femmes. Il peut être aussi différent entre chaque règle, on appelle ça
      avoir un cycle irrégulier.
    </p>
    <p>
      Par exemple, on parle souvent des cycles de 28 jours. On estime
      classiquement la période d’ovulation au 14ème jour (en comptant à partir
      du premier jour des règles). Les spermatozoïdes peuvent survivre 5 jours
      environ après un rapport sexuel. La fécondation (rencontre entre un
      spermatozoïde et l’ovocyte pour donner le futur bébé) peut arriver jusqu’à
      3 jours après l’ovulation La période de “fertilité” peut donc durer
      jusqu’à 8 jours (5 jours avant l’ovulation et 3 jours après).
    </p>
    <p>
      Donc une femme utilisant cette méthode va éviter d’avoir des rapports
      durant cette période.
    </p>
    <p>
      Comme les cycles d’une femme peuvent changer d’un mois à l’autre et que
      l’ovulation est imprévisible (pas forcément en milieu de cycle), un risque
      de grossesse avec cette méthode est important surtout pour les femmes avec
      un cycle irrégulier.
    </p>
    <p className="moyen-section">La méthode des températures</p>
    <p>
      Cette méthode permet de{" "}
      <span className="moyen-emph">
        repérer la période d’ovulation grâce à la prise de la température
      </span>{" "}
      du corps. La température habituelle d’une femme s’élève légèrement au
      moment de l’ovulation de 0,2 à 0,4°C (on passe de 37°C à 37,4° par
      exemple).
    </p>
    <p>
      Comme la fécondation (rencontre entre un spermatozoïde et l’ovocyte pour
      donner le futur bébé) peut arriver jusqu’à 3 jours après l’ovulation, une
      femme utilisant cette méthode aura des rapports sexuels après ces 3 jours
      de montée de température.
    </p>
    <p>
      Il faut prendre sa température tous les jours le matin à la même heure au
      lit avant de se lever.
    </p>
    <p>
      Comme les spermatozoïdes survivent 5 jours après un rapport sexuel, la
      période avant la montée de température est risquée.
    </p>
    <p>
      La température peut s’élever pour plein d’autres raisons comme un simple
      rhume.
    </p>
    <p className="moyen-section">L’observation de la glaire cervicale</p>
    <p>
      Cette méthode consiste à{" "}
      <span className="moyen-emph">
        reconnaître la période de l’ovulation en fonction de l’aspect de la
        glaire
      </span>{" "}
      (sécrétions du col de l’utérus). Le col de l’utérus est l’entrée de
      l’utérus, on le retrouve au fond du vagin. La femme doit examiner la
      glaire avec ses doigts.
    </p>
    <p>
      La période d’ovulation (risque de tomber enceinte) est au moment où les
      sécrétions deviennent plus abondantes, plus fluides et provoquent un
      écoulement vaginal (ou "pertes blanches") caractéristique.
    </p>
    <p>
      Il est important de retenir que beaucoup de choses peuvent modifier la
      glaire et sa consistance : le désir sexuel, le sperme, les infections
      vaginales…
    </p>
    <p>
      Comme les spermatozoïdes survivent 5 jours après un rapport sexuel, la
      période avant la modification de la glaire est risquée.
    </p>
    <p className="moyen-section">La symptothermie</p>
    <p>
      Cette méthode{" "}
      <span className="moyen-emph">
        associe le calcul des cycles décrit dans l’abstinence périodique, la
        méthode des températures et l’observation de la glaire cervicale
      </span>
      .
    </p>
    <p>
      C’est la méthode naturelle la plus efficace. Des formations existent pour
      aider les femmes à la réaliser.
    </p>
    <p className="moyen-section">Le retrait</p>
    <p>
      L’homme{" "}
      <span className="moyen-emph">
        se retire avant d’éjaculer dans (ou à l’entrée) du le vagin de sa
        partenaire
      </span>
      .
    </p>
    <p>
      Cette méthode nécessite une grande confiance en son partenaire et l’homme
      doit bien se connaître.
    </p>
    <p>
      Plus de 2 femmes sur 10 tombent enceintes avec cette méthode.
      L’éjaculation est un réflexe qui est difficile à contrôler et il y a
      également émission de spermatozoïdes avant l’éjaculation.
    </p>
    <p className="moyen-section">Aide d’un appareil</p>
    <p>
      Des appareils vendus en pharmacie, sur internet ou en grande surface
      peuvent aider à déterminer la période d’ovulation. Ces appareils sont
      coûteux et peuvent se tromper.
    </p>
  </>
);

export default Naturelles;
