import {
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonImg,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useState } from "react";
import { MoyenType } from "../actions/types";
import MoyenModal from "../components/moyenModal/MoyenModal";
import moyens from "../data/moyens";
import useModal from "../hooks/useModal";
import "./Moyen.css";

const Moyens: React.FC = () => {
  const [modalShowing, showModal, hideModal] = useModal();
  const [selectedMoyen, setSelectedMoyen] = useState<MoyenType>(moyens[0]);

  const onCardClick = (moyen: MoyenType) => {
    setSelectedMoyen(moyen);
    showModal();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Les moyens</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonGrid>
          <IonRow class="ion-align-items-center">
            {moyens.map((moyen, i) => (
              <IonCol key={`moyen-${i}`} size="6" className="ion-no-padding">
                <IonCard onClick={() => onCardClick(moyen)}>
                  <IonImg src={moyen.img} />
                  <IonCardHeader>
                    <IonCardTitle>{moyen.titre}</IonCardTitle>
                  </IonCardHeader>
                </IonCard>
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
        <MoyenModal
          closeModal={hideModal}
          moyen={selectedMoyen}
          showModal={modalShowing}
        />
      </IonContent>
    </IonPage>
  );
};

export default Moyens;
