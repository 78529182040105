import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/react";
import React from "react";
import { QUESTIONNAIRE_URL } from "../../constants/url";

const SatisfactionCard: React.FC = () => {
  const openQuestionnaire = () => window.open(QUESTIONNAIRE_URL);

  return (
    <IonCard color="primary" onClick={openQuestionnaire}>
      <IonCardHeader>
        <IonCardTitle>Questionnaire</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        J’ai besoin de vous. Cette application a été créée dans le cadre d’une
        thèse de Médecine Générale. Votre point de vue concernant l’application
        est très important. Anonyme, en 5 minutes, répondez au questionnaire de
        satisfaction en cliquant ici.
      </IonCardContent>
    </IonCard>
  );
};

export default SatisfactionCard;
