
import { Moyen } from '../../constants/moyen'; import { QuestionType } from '../questions';

export const question19: QuestionType = {
    id: 18,
    type: "multiple",
    question: "Avez-vous ou avez vous eu des problèmes de santé faisant partie de la liste ci dessous ? (Réponses multiples)",
    options: [
        {
            libelle: "Phlébite (caillot dans les vaisseaux des jambes)",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.ANNEAU, Moyen.PATCH],
                        phrase: "Existe une contre-indication probable liée à vos problèmes de santé",
                    }
                ]
            }
        }, {
            libelle: "Embolie pulmonaire (caillot dans les vaisseaux des poumons)",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.ANNEAU, Moyen.PATCH],
                        phrase: "Existe une contre-indication probable liée à vos problèmes de santé",
                    }
                ]
            }
        }, {
            libelle: "Infarctus du myocarde (attaque cardiaque)",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.PATCH, Moyen.ANNEAU],
                        phrase: "Existe une contre-indication probable liée à vos problèmes de santé",
                    }
                ]
            }
        }, {
            libelle: "Accident vasculaire cérébral (AVC)",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.ANNEAU, Moyen.PATCH],
                        phrase: "Existe une contre-indication probable liée à vos problèmes de santé",
                    }
                ]
            }
        }, {
            libelle: "Anomalies de la coagulation",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.ANNEAU, Moyen.PATCH],
                        phrase: "Existe une contre-indication probable liée à vos problèmes de santé",
                    }
                ]
            }
        }, {
            libelle: "Anorexie",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.PILULE_M],
                        phrase: "Existe une contre-indication probable liée à vos problèmes de santé",
                    }
                ]
            }
        }, {
            libelle: "Vomissements fréquents",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.PILULE_M],
                        phrase: "Existe une contre-indication probable liée à vos problèmes de santé",
                    }
                ]
            }
        }, {
            libelle: "Cancer de l'endomètre",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.PILULE_M, Moyen.ANNEAU, Moyen.PATCH, Moyen.IMPLANT, Moyen.STERILET_HORMONAL, Moyen.STERILET_CUIVRE],
                        phrase: "Existe une contre-indication probable liée à vos problèmes de santé",
                    }
                ]
            }
        }, {
            libelle: "Cancer du sein ou des ovaires",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.PILULE_M, Moyen.ANNEAU, Moyen.PATCH, Moyen.IMPLANT, Moyen.STERILET_HORMONAL],
                        phrase: "Existe une contre-indication probable liée à vos problèmes de santé",
                    }
                ]
            }
        }, {
            libelle: "Diabète (en dehors de la grossesse)",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.ANNEAU, Moyen.PATCH],
                        phrase: "Existe une contre indication probable si avez plus de 35 ans, si vous fumez ou avez d'autres pathologies",
                    }
                ]
            }
        }, {
            libelle: "Hypertension artérielle",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.ANNEAU, Moyen.PATCH],
                        phrase: "Existe une contre indication probable si avez plus de 35 ans, si vous fumez ou avez d'autres pathologies",
                    }
                ]
            }
        }, {
            libelle: "Problème de cholestérol",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.ANNEAU, Moyen.PATCH],
                        phrase: "Existe une contre indication probable si avez plus de 35 ans, si vous fumez ou avez d'autres pathologies",
                    }
                ]
            }
        }, {
            libelle: "Migraine avec trouble de la vision, du langage, des mouvements ou de la sensibilité du corps ",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.ANNEAU, Moyen.PATCH],
                        phrase: "Existe une contre-indication probable liée à vos problèmes de santé",
                    }
                ]
            }
        }, {
            libelle: "Migraine sans trouble de la vision, du langage, des mouvements ou de la sensibilité du corps ",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.ANNEAU, Moyen.PATCH],
                        phrase: "Existe une contre indication probable si avez plus de 35 ans, si vous fumez ou avez d'autres pathologies",
                    }
                ]
            }
        }, {
            libelle: "Maladie du foie ou hépatite ",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.ANNEAU, Moyen.PATCH, Moyen.PILULE_M, Moyen.STERILET_HORMONAL, Moyen.IMPLANT],
                        phrase: "Existe une contre-indication probable liée à vos problèmes de santé",
                    }
                ]
            }
        }, {
            libelle: "Pancréatite ",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.ANNEAU, Moyen.PATCH],
                        phrase: "Existe une contre-indication probable liée à vos problèmes de santé",
                    }
                ]
            }
        }, {
            libelle: "Lupus ",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.ANNEAU, Moyen.PATCH],
                        phrase: "Existe une contre-indication probable liée à vos problèmes de santé",
                    }
                ]
            }
        }, {
            libelle: "Drépanocytose ",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.ANNEAU, Moyen.PATCH],
                        phrase: "Existe une contre-indication probable liée à vos problèmes de santé",
                    }
                ]
            }
        }, {
            libelle: "Infection de l’utérus ou des trompes ou infection génitale haute (IGH)",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.STERILET_CUIVRE, Moyen.STERILET_HORMONAL],
                        phrase: "Existe une contre-indication probable liée à vos problèmes de santé",
                    }
                ]
            }
        }, {
            libelle: "Malformation de l'utérus ou fibrome",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.STERILET_CUIVRE, Moyen.STERILET_HORMONAL],
                        phrase: "Existe une contre-indication probable liée à vos problèmes de santé",
                    }
                ]
            }
        }, {
            libelle: "Cancer du col de l'utérus",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.STERILET_CUIVRE, Moyen.STERILET_HORMONAL],
                        phrase: "Existe une contre-indication probable liée à vos problèmes de santé",
                    }
                ]
            }
        }, {
            libelle: "Saignements vaginaux en dehors des règles et inexpliqués",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.STERILET_CUIVRE, Moyen.STERILET_HORMONAL, Moyen.PILULE_O, Moyen.PATCH, Moyen.ANNEAU, Moyen.PILULE_M, Moyen.IMPLANT],
                        phrase: "Existe une contre-indication probable liée à vos problèmes de santé",
                    }
                ]
            }
        }, {
            libelle: "Infection urinaire à répétition",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.CAPE, Moyen.DIAPHRAGME],
                        phrase: "Existe une contre-indication probable liée à vos problèmes de santé",
                    }
                ]
            }
        }, {
            libelle: "Prolapsus (descente d'organe)",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.CAPE, Moyen.DIAPHRAGME],
                        phrase: "Existe une contre-indication probable liée à vos problèmes de santé",
                    }
                ]
            }
        }, {
            libelle: "Aucun",
            coche: {
            },
        }, {
            libelle: "Je ne sais pas",
            coche: {

            },
        }
    ]
}
