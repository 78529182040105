
import { Moyen } from '../../constants/moyen'; import { QuestionType } from '../questions';

export const question6: QuestionType = {
    id: 5,
    type: "radio",
    question: "Avez-vous eu votre enfant il y a moins de 6 mois ?",
    options: [
        {
            libelle: "Oui",
            coche: {
                avantages: [
                    {
                        moyens: [Moyen.CAPE, Moyen.DIAPHRAGME, Moyen.PRESERVATIF_M, Moyen.PRESERVATIF_F, Moyen.STERILET_CUIVRE, Moyen.STERILET_HORMONAL, Moyen.IMPLANT, Moyen.PILULE_M],
                        phrase: "Possible pendant l'allaitement",
                    },
                ],
                inconvenients: [
                    {
                        moyens: [Moyen.PATCH, Moyen.ANNEAU, Moyen.PILULE_O],
                        phrase: "Non conseillée pendant les 6 premiers mois de l’allaitement",
                    },
                ]
            }
        }, {
            libelle: "Non",
            coche: {
                avantages: [
                    {
                        moyens: [Moyen.PATCH, Moyen.ANNEAU, Moyen.PILULE_O],
                        phrase: "Possible après les 6 mois de l'enfant",
                    }, {
                        moyens: [Moyen.CAPE, Moyen.DIAPHRAGME, Moyen.PRESERVATIF_M, Moyen.PRESERVATIF_F, Moyen.STERILET_CUIVRE, Moyen.STERILET_HORMONAL, Moyen.IMPLANT, Moyen.PILULE_M],
                        phrase: "Possible pendant l'allaitement",
                    }
                ],
            }
        }
    ]
}
