import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import { MoyenType } from "../../actions/types";
import "./moyens.css";

type MoyenModalProps = {
  closeModal: () => void;
  moyen: MoyenType;
  showModal: boolean;
};

const MoyenModal: React.FC<MoyenModalProps> = ({
  closeModal,
  moyen,
  showModal,
}) => (
  <IonModal isOpen={showModal} onWillDismiss={closeModal}>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonButton onClick={closeModal}>
            <IonIcon icon="close" />
          </IonButton>
        </IonButtons>
        <IonTitle>{moyen?.titre}</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent className="ion-padding">
      <moyen.composant />
    </IonContent>
  </IonModal>
);

export default MoyenModal;
