import React from "react";

const Biberon: React.FC = () => (
  <>
    <p>Les méthodes contraceptives efficaces sont : </p>
    <ul>
      <li className="ion-margin-bottom">
        <span className="moyen-emph">La pilule progestative.</span> Le premier
        comprimé est à débuter classiquement{" "}
        <span className="moyen-emph">21 jours après votre accouchement</span>.
        (Voir “pilule progestative” pour plus d’informations)
      </li>
      <li className="ion-margin-bottom">
        <span className="moyen-emph">L’implant.</span> Il est posé{" "}
        <span className="moyen-emph">21 jours après votre accouchement</span>. A
        noter, que certaines maternité posent les implants dans les jours
        suivant votre accouchement. (Voir “implant” pour plus d’informations)
      </li>
      <li className="ion-margin-bottom">
        <span className="moyen-emph">
          Le DIU (Dispositif Intra Utérin) ou stérilet au cuivre ET hormonal.
        </span>
        Il peut être posé
        <span className="moyen-emph">
          {" "}
          4 semaines après un accouchement par les voies naturelles et 6
          semaines après une césarienne
        </span>
        . Il peut être posé par un médecin ou une sage-femme au moment de la
        consultation postnatale qui a lieu entre 6 et 8 semaines après
        l’accouchement. (Voir “DIU au cuivre” et “DIU hormonal” pour plus
        d’informations) Si la pose du stérilet est prévue plus de 4 semaines
        après l’accouchement, un autre moyen de contraception en attendant la
        pose du stérilet doit être envisagé (pilule, préservatif).
      </li>
      <li className="ion-margin-bottom">
        <span className="moyen-emph">La pilule oestroprogestative.</span> Elle
        peut être envisagée{" "}
        <span className="moyen-emph">21 jours après l’accouchement</span>.
        Certains problèmes de santé personnels et familiaux existant avant la
        grossesse, pendant la grossesse et pendant l’accouchement (césarienne
        notamment) peuvent rendre impossible sa prise pendant les 6 semaines
        après l’accouchement. Le risque en cas de tabagisme est aussi à prendre
        en compte. Si la pilule ne peut être débutée à 21 jours de
        l’accouchement, un autre moyen de contraception doit être envisagé en
        attendant (préservatif).
      </li>
      <li className="ion-margin-bottom">
        <span className="moyen-emph">
          NE PAS METTRE DANS CONTRACEPTIFS EFFICACE
        </span>
        <br />
        <span className="moyen-emph">Les préservatifs</span> peuvent être
        envisagés aussi. Ils ne sont efficaces qu’en cas d’utilisation parfaite.
        (Voir “préservatif masculin” et “préservatif féminin” pour plus
        d’informations).
      </li>
    </ul>
  </>
);

export default Biberon;
