
export const getAge = (date: string): number => {
    let dob = new Date(date);
    //calculate month difference from current date in time  
    let month_diff = Date.now() - dob.getTime();

    //convert the calculated difference in date format  
    let age_dt = new Date(month_diff);

    //extract year from date      
    let year = age_dt.getUTCFullYear();

    //now calculate the age of the user  
    let age = Math.abs(year - 1970);

    return age
}