import React from "react";

const Anneau: React.FC = () => (
  <>
    <p className="moyen-section">Présentation</p>
    <p>
      L’anneau vaginal est un{" "}
      <span className="moyen-emph">anneau flexible</span> en plastique qui
      <span className="moyen-emph"> s’insère au fond du vagin</span>. Il
      contient deux hormones : l’<span className="moyen-emph">oestrogène</span>{" "}
      et la
      <span className="moyen-emph"> progestérone</span>.
    </p>
    <p>
      Il s’insère <span className="moyen-emph">comme un tampon</span>. Sa
      position n’est pas importante mais il ne faut pas que vous le sentiez. On
      le garde pendant 3 semaines. Retirer l’anneau la 4ème semaine provoque
      l’arrivée des règles . Un anneau protège pendant ces{" "}
      <span className="moyen-emph">4 semaines</span> (3 semaines avec anneau et
      la semaine sans). Puis on en remet un autre pour les 3 prochaines semaines
      et ainsi de suite.
    </p>
    <p className="moyen-section">Efficacité</p>
    <p>
      S’il est utilisé sans erreur , son efficacité est supérieure à
      <span className="moyen-emph"> 99.7%</span>. Si on prend en compte les
      erreurs (mauvaises utilisations, oublis), son efficacité est de
      <span className="moyen-emph"> 91%</span>.
    </p>
    <p>
      Pour la première utilisation, si vous le mettez le premier jour de vos
      règles, il est efficace <span className="moyen-emph">tout de suite</span>.
      Sinon, il faudra attendre <span className="moyen-emph">7 jours </span>
      avant d’avoir une efficacité.
    </p>
    <p className="moyen-section">Comment l'obtenir ?</p>
    <p>
      Il nécessite une <span className="moyen-emph">prescription</span> par un
      médecin ou une sage-femme pour l’obtenir en pharmacie. Il n’est
      <span className="moyen-emph"> pas remboursé</span> et coûte moins de
      <span className="moyen-emph"> 10 euros</span> par mois.
    </p>
    <p className="moyen-section">Les points positifs</p>
    <p>Il permet de :</p>
    <ul className="moyen-emph">
      <li>régulariser les cycles</li>
      <li>diminuer les douleurs de règles</li>
      <li>diminuer le flux des règles</li>
    </ul>
    <p>
      Il faut y penser <span className="moyen-emph">1 fois par mois</span>.
      <br />
      Il est <span className="moyen-emph">
        discret et facile à utiliser
      </span>. <br />
      Il peut être pratique pour les personnes qui se déplacent beaucoup et en
      cas de décalage horaire.
    </p>

    <p className="moyen-section">Les points négatifs</p>
    <p>Il peut parfois (comme la pilule oestroprogestative):</p>
    <ul className="moyen-emph">
      <li>Augmenter l'appétit</li>
      <li>Donner des sautes d'humeur</li>
      <li>Modifier le désir sexuel</li>
      <li>Donner des douleurs dans les seins</li>
      <li>Maux de tête</li>
    </ul>
    <p>On peut parfois le sentir pendant un rapport sexuel.</p>
    <p>
      L’utilisation de l’anneau augmente le risque de thrombose (bouchon dans
      les vaisseaux). Ce risque augmente d’autant plus{" "}
      <span className="moyen-emph">si vous fumez</span>.
    </p>
    <p>
      Il{" "}
      <span className="moyen-emph">
        ne peut pas être utilisé par tout le monde
      </span>{" "}
      et certains médicaments pris tous les jours peuvent diminuer son
      efficacité (en parler avec un professionnel de santé).
    </p>
    <p className="moyen-section">En cas d'oubli</p>
    <p>
      <span className="moyen-emph-danger">Vous êtes toujours protégée :</span>
      <ul>
        <li>
          <span className="moyen-emph">
            si l’anneau est expulsé et que vous le remarquez en moins de 3h.
          </span>{" "}
          ll faut le remettre en place immédiatement.
        </li>
        <li>
          <span className="moyen-emph">
            si l'anneau est laissé en place plus de 3 semaines mais moins de 4
            semaines.
          </span>{" "}
          Il faut remettre en place un nouvel anneau immédiatement.
        </li>
        <li>
          <span className="moyen-emph">
            si vous oubliez de remettre un nouvel anneau mais que vous auriez dû
            le remettre il y a moins de 24h,
          </span>{" "}
          mettez en place un nouvel anneau et continuez comme habituellement.
        </li>
      </ul>
    </p>
    <p>
      <span className="moyen-emph-danger">Vous n'êtes plus protégée :</span>
      <ul>
        <li>
          <span className="moyen-emph">
            si l’anneau est expulsé plus de 3h.
          </span>{" "}
          Remettez-le quand même en place immédiatement et gardez-le jusqu'au
          jour prévu de retrait.
        </li>
        <li>
          <span className="moyen-emph">
            si vous oubliez de remettre un nouvel anneau et que vous auriez dû
            le remettre il y a plus de 24h ou si vous l’avez laissé en place
            plus de 4 semaines.
          </span>{" "}
          Mettez en place quand même un nouvel anneau le plus rapidement
          possible.
        </li>
      </ul>
    </p>

    <p>
      <span className="moyen-color">
        Dans ces cas, vous n’êtes plus protégée
      </span>{" "}
      donc il faudra pendant les 7 jours suivant l’oubli, utiliser une
      contraception mécanique (les préservatifs).
      <br />
      <span className="moyen-color">Au bout des 3 semaines</span>, enchaînez
      avec un autre anneau sans réaliser la semaine de pause entre les 2
      anneaux.
    </p>
    <p>
      <span className="moyen-color">
        Si vous avez eu un rapport sexuel dans les 5 jours précédant l’oubli
      </span>
      , il faut prendre une contraception d’urgence. (Voir “La contraception
      d’urgence”)
    </p>
  </>
);

export default Anneau;
