import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import ChoisirSaContraceptionLink from "../components/ChoisirSaContraceptionLink";
import QuestionnaireLink from "../components/QuestionnaireLink";

const Apropos: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>A Propos</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="homepage ion-padding-horizontal">
        <p>
          La création de cette application a été réalisée dans le cadre d’une
          thèse de Médecine Générale réalisée par Camille Teyssier, interne en
          Médecine Générale.
        </p>
        <p>
          Le test sur l’application s’inspire fortement de l’outil “Quelle
          contraception me convient le mieux ?” sur le site
          <ChoisirSaContraceptionLink />.
          <br />
          Ma source d’information principale pour les informations sur chaque
          méthode de contraception est également
          <ChoisirSaContraceptionLink />.
          <br />
          Le site est géré par Santé Publique France.
        </p>
        <p>Des informations ont été utilisées provenant du site vidal.fr</p>
        <p>
          Le développement de l'application a été réalisé par Thomas Boucher.
        </p>
        <h2>Votre avis</h2>
        <p>
          Cette application est créée dans le cadre d’une thèse de Médecine
          Générale. Votre avis est très important. En répondant au questionnaire
          de satisfaction, vous m’aiderez énormément. Il est anonyme et prend
          environ 5 minutes.
          <QuestionnaireLink />
        </p>
        <p style={{ marginBottom: "150px" }} />
      </IonContent>
    </IonPage>
  );
};

export default Apropos;
