
import { Moyen } from '../../constants/moyen'; import { QuestionType } from '../questions';

export const question12: QuestionType = {
    id: 11,
    type: "multiple",
    question: "Vous êtes prêtes à gérer votre contraception : Réponses multiples",
    options: [
        {
            libelle: "A chaque rapport",
            coche: {
                avantages: [
                    {
                        moyens: [Moyen.PRESERVATIF_M, Moyen.PRESERVATIF_F, Moyen.DIAPHRAGME, Moyen.CAPE, Moyen.NATURELLES],
                        phrase: "A penser à chaque rapport",
                    }
                ]
            },
            noncoche: {
                inconvenients: [
                    {
                        moyens: [Moyen.PRESERVATIF_M, Moyen.PRESERVATIF_F, Moyen.DIAPHRAGME, Moyen.CAPE, Moyen.NATURELLES],
                        phrase: "A penser à chaque rapport",
                    }
                ]
            }
        }, {
            libelle: "Tous les jours",
            coche: {
                avantages: [
                    {
                        moyens: [Moyen.PILULE_M, Moyen.PILULE_O],
                        phrase: "A penser tous les jours",
                    }
                ]
            },
            noncoche: {
                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.PILULE_M],
                        phrase: "A penser tous les jours",
                    }
                ]
            }
        }, {
            libelle: "Une fois par semaine",
            coche: {
                avantages: [
                    {
                        moyens: [Moyen.PATCH],
                        phrase: "A penser toutes les semaines",
                    },
                ],
            },
            noncoche: {
                inconvenients: [
                    {
                        moyens: [Moyen.PATCH],
                        phrase: "A penser toutes les semaines",
                    }
                ]
            }
        }, {
            libelle: "Une fois par mois",
            coche: {
                avantages: [
                    {
                        moyens: [Moyen.ANNEAU],
                        phrase: "A penser tous les mois",
                    },
                ],
            },
            noncoche: {
                inconvenients: [
                    {
                        moyens: [Moyen.ANNEAU],
                        phrase: "A penser tous les mois",
                    }
                ]
            }
        }, {
            libelle: "Tous les trois ans ou plus",
            coche: {
                avantages: [
                    {
                        moyens: [Moyen.IMPLANT],
                        phrase: "A penser tous les 3 ans",
                    },
                    {
                        moyens: [Moyen.STERILET_CUIVRE, Moyen.STERILET_HORMONAL],
                        phrase: "A penser tous les 5 ans",
                    },
                    {
                        moyens: [Moyen.STERILISATION],
                        phrase: "Ne plus y penser à vie",
                    },
                ]
            },
            noncoche: {
                inconvenients: [
                    {
                        moyens: [Moyen.IMPLANT],
                        phrase: "A penser tous les 3 ans",
                    },
                    {
                        moyens: [Moyen.STERILET_CUIVRE, Moyen.STERILET_HORMONAL],
                        phrase: "A penser tous les 5 ans",
                    },
                    {
                        moyens: [Moyen.STERILISATION],
                        phrase: "Ne plus y penser à vie",
                    },
                ]
            }
        }, {
            libelle: "Je ne sais pas",
            coche: {
            }
        }, {
            libelle: "Je n'ai pas de préférence",
            coche: {
            }
        },
    ]
}
