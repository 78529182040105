import {
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
} from "@ionic/react";
import { chevronForward } from "ionicons/icons";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { ReponseActionType } from "../../actions/types";
import { QuestionsContext } from "../../contexts/contexts";
import { GoToType, QuestionType } from "../../data/questions";

type RadioQuestionTypeProps = {
  slideTo: (slideId: number) => Promise<void>;
  question: QuestionType;
};

type RadioResponseType = {
  coche: Array<String>;
  noncoche: Array<String>;
  goTo?: GoToType;
};

const RadioQuestionType: React.FC<RadioQuestionTypeProps> = ({
  slideTo,
  question,
}) => {
  const { dispatch } = useContext(QuestionsContext);

  const [values, setValues] = useState<RadioResponseType>({
    coche: [],
    noncoche: [],
  });

  useEffect(() => {
    setValues({
      coche: [],
      noncoche: question.options?.map((option) => option.libelle),
    });
  }, [question]);

  const slideToNext = useCallback(() => {
    dispatch({
      type: ReponseActionType.ANSWER_RADIO,
      payload: {
        index: question.id,
        reponse: values,
        options: question.options,
      },
    });
    slideTo(values.goTo?.questionIndex || question.id + 1);
  }, [dispatch, question, slideTo, values]);

  const onSelect = (e: any) => {
    setValues({
      coche: [e.detail.value],
      noncoche: question.options
        ?.filter((option) => option.libelle !== e.detail.value)
        .map((el) => el.libelle),
      goTo: question.options
        ?.filter((option) => option.libelle === e.detail.value)
        .map((el) => el.coche?.goTo)[0],
    });
  };

  return (
    <>
      <IonList>
        <IonRadioGroup onIonChange={onSelect} value={values.coche[0]}>
          {question.options.map((reponse, i) => (
            <IonItem key={`reponse-${i}`}>
              <IonLabel className="ion-text-wrap">{reponse.libelle}</IonLabel>
              <IonRadio slot="start" value={reponse.libelle} />
            </IonItem>
          ))}
        </IonRadioGroup>
      </IonList>
      <IonButton
        onClick={slideToNext}
        style={{ margin: "32px 0px" }}
        disabled={!values.coche.length}
      >
        Suivant
        <IonIcon slot="end" icon={chevronForward} />
      </IonButton>
    </>
  );
};

export default RadioQuestionType;
export type { RadioResponseType };
