
import { Moyen } from '../../constants/moyen'; import { QuestionType } from '../questions';

export const question17: QuestionType = {
    id: 16,
    type: "radio",
    question: "Seriez-vous à l’aise si la contraception dépend de votre partenaire ?",
    options: [
        {
            libelle: "Oui",
            coche: {
            }
        }, {
            libelle: "Non",
            coche: {
                inconvenients: [
                    {
                        moyens: [Moyen.PRESERVATIF_M],
                        phrase: "Sous la dépendance de votre partenaire pour les préservatifs masculins ",
                    }
                ]
            }
        }, {
            libelle: "Je ne sais pas/Je n'ai pas d'avis",
            coche: {
            },
        }
    ]
}
