import React from "react";

const Progestatif: React.FC = () => (
  <>
    <p className="moyen-section">Présentation</p>
    <p>
      Une hormone, la <span className="moyen-emph">progestérone</span>, est
      injectée par piqûre intramusculaire{" "}
      <span className="moyen-emph">tous les 3 mois</span>. Durant ces 3 mois,
      l’effet contraceptif est assuré.
    </p>
    <p className="moyen-section">Efficacité</p>
    <p>
      En pratique (en comptant les oublis et autres), l’efficacité est de{" "}
      <span className="moyen-emph">94%</span>. L’efficacité théorique (si
      parfaitement utilisée) est de 99.7%. Si le délai de 12 semaines est
      dépassé, il y a un risque de grossesse.
    </p>
    <p className="moyen-section">Comment l'obtenir ?</p>
    <p>
      Cela nécessite une <span className="moyen-emph">prescription</span> par un
      médecin ou une sage-femme. L’injection doit être réalisée toutes les 12
      semaines par un médecin, une infirmière ou une sage-femme. Une injection
      coûte 5,32 euros environ et est{" "}
      <span className="moyen-emph">remboursée</span> par l’Assurance Maladie.
    </p>
    <p className="moyen-section">Les points positifs</p>
    <p>Il permet de:</p>
    <ul className="moyen-emph">
      <li>diminuer les douleurs de règles</li>
      <li>diminuer le flux des règles</li>
    </ul>
    <p>
      Cette pilule peut avoir l’effet{" "}
      <span className="moyen-emph">d’arrêter complètement les règles</span>, ce
      qui peut être voulu.
    </p>
    <p className="moyen-section">Les points négatifs</p>
    <p>Elles peuvent parfois:</p>
    <ul className="moyen-emph">
      <li>donner de petits saignements ponctuels (spotting)</li>
      <li>des règles irrégulières</li>
      <li>augmenter l'appétit</li>
      <li>donner des sautes d'humeur</li>
      <li>modifier le désir sexuel</li>
      <li>donner des douleurs aux seins</li>
      <li>maux de tête</li>
      <li>augmenter l'acné</li>
    </ul>
    <p>
      Attention, on prescrit cette méthode pour les femmes{" "}
      <span className="moyen-emph">
        qui ne peuvent pas avoir d’autres moyens de contraception
      </span>
      . Les injections ont une{" "}
      <span className="moyen-emph">influence sur les os</span> et donc on ne
      peut pas le proposer chez les adolescentes et adultes jeunes ou les femmes
      ayant des risques d’ostéoporose (maladie de l’os).
    </p>
    <p>
      Toutes les femmes ne peuvent avoir cette méthode de contraception du fait
      de leurs problèmes de santé. Certains médicaments pris tous les jours
      peuvent diminuer son efficacité (en parler avec un professionnel de
      santé).
    </p>
  </>
);

export default Progestatif;
