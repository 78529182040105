import React from "react";

const Implant: React.FC = () => (
  <>
    <p className="moyen-section">Présentation</p>
    <p>
      L’implant contraceptif est un{" "}
      <span className="moyen-emph">petit bâtonnet</span> en plastique de la
      taille d’une allumette (4 cm de long sur 2 mm de diamètre).
    </p>
    <p>
      Il est inséré <span className="moyen-emph">sous la peau</span> au niveau
      de l’intérieur d’un bras par un médecin ou une sage-femme. La pose de
      l’implant est peu douloureuse voire indolore car accompagnée d’une
      anesthésie locale de la peau.Il diffuse une hormone :{" "}
      <span className="moyen-emph">la progestérone</span>. Il ne se voit pas
      mais peut se sentir si on essaye de le toucher au niveau du bras. Pour le
      retirer, il faut réaliser une petite incision (toujours après une
      anesthésie de la peau) et un nouvel implant peut être inséré
      immédiatement.
    </p>
    <p>
      Il permet de ne plus penser à sa contraception
      <span className="moyen-emph"> pendant 3 ans</span>. Il peut également être
      retiré avant si vous le souhaitez.
    </p>

    <p className="moyen-section">Efficacité</p>
    <p>
      C’est le moyen de contraception
      <span className="moyen-emph"> le plus efficace</span> à ce jour. Son
      efficacité pratique est de <span className="moyen-emph">99.9%</span>.
      <br />
      Il est efficace <span className="moyen-emph">
        1 jour après la pose
      </span>{" "}
      s’il est inséré entre le premier jour du cycle (premier jour des
      menstruations) et le 5ème jour du cycle, sinon ce sera{" "}
      <span className="moyen-emph">au bout de 7 jours.</span>.
    </p>
    <p className="moyen-section">Comment l'obtenir ?</p>
    <p>
      Son prix est de 101.58 euros mais il est
      <span className="moyen-emph"> remboursé</span>. Il faut aller le chercher
      en pharmacie avec une
      <span className="moyen-emph"> prescription</span> d’ un médecin ou d’une
      sage-femme. Si vous avez moins de 26 ans, il n’y a aucun frais à avancer
      pour obtenir l’implant en pharmacie ainsi que pour la pose et le retrait
      de l’implant.
    </p>
    <p className="moyen-section">Les points positifs</p>
    <p>Il permet :</p>
    <ul className="moyen-emph">
      <li>de diminuer le flux des règles</li>
      <li>de diminuer les douleurs de règles</li>
    </ul>
    <p>
      Cet implant peut avoir l’effet{" "}
      <span className="moyen-emph">d’arrêter complètement les règles</span>, ce
      qui peut être voulu. <br />
      Il peut être utilisé même si vous fumez.
      <br />
      Certaines personnes à qui la pilule oestroprogestative ou combinée est
      déconseillée ou interdite peuvent utiliser l’implant.
      <br />
      Il peut être posé même en cas d’allaitement.
    </p>
    <p className="moyen-section">Les points négatifs</p>
    <p>Il peut parfois:</p>
    <ul className="moyen-emph">
      <li>donner de petits saignements irréguliers</li>
      <li>augmenter l'appétit ou faire prendre du poids</li>
      <li>donner des sautes d'humeur</li>
      <li>modifier le désir sexuel</li>
      <li>donner des douleurs aux seins</li>
      <li>maux de tête</li>
      <li>augmenter l'acné</li>
    </ul>
    <p>
      Certains médicaments pris tous les jours peuvent diminuer son efficacité
      (en parler avec un professionnel de santé).
    </p>
    <p>
      A noter, qu’il existe de très rares cas où l’implant peut se déplacer dans
      le bras et dans un vaisseau sanguin. C’est le cas si l’implant est posé
      trop profondément sous la peau.
    </p>
  </>
);

export default Implant;
