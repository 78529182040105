import React from "react";

const Cape: React.FC = () => (
  <>
    <p className="moyen-section">Présentation</p>
    <p>
      La cape cervicale est comme un <span className="moyen-emph">chapeau</span>{" "}
      qui se glisse dans le <span className="moyen-emph">vagin</span>, avant un
      rapport, pour empêcher le passage des spermatozoïdes.
    </p>
    <p>
      Elle peut être posée jusqu’à 2h{" "}
      <span className="moyen-emph">avant le rapport sexuel</span> et doit être
      laissée en place 8h après le rapport. Elle peut être utilisée plusieurs
      fois mais doit être lavée entre chaque utilisation (à l’eau et au savon
      neutre).
    </p>
    <p className="moyen-section">Efficacité</p>
    <p>
      Si on l’utilise parfaitement (sans erreur de manipulation), elle est
      efficace à 91% si vous n’avez jamais eu d’enfant et à 74% si vous avez
      déjà eu un enfant.
    </p>
    <p>
      Dans la vie de tous les jours, l’efficacité est de{" "}
      <span className="moyen-emph">84% si vous n’avez jamais eu d’enfant </span>
      (2 femmes sur 10 tombent enceintes avec ce moyen) et de{" "}
      <span className="moyen-emph">68% si vous avez déjà eu un enfant </span>
      (plus de 3 femmes sur 10 tombent enceintes avec ce moyen).
    </p>
    <p>
      Il est conseillé d’utiliser des{" "}
      <span className="moyen-emph">spermicides</span> en association pour une
      meilleure efficacité. (voir “Les spermicides” pour plus d‘informations)
    </p>
    <p className="moyen-section">Comment l'obtenir ?</p>
    <p>
      Il nécessite une <span className="moyen-emph">prescription</span> par un
      médecin ou une sage-femme pour l’obtenir en pharmacie. La cape cervicale
      coûte environ 60 euros. Elle n’est{" "}
      <span className="moyen-emph">pas remboursée</span>.
    </p>
  </>
);

export default Cape;
