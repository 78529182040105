
import { Moyen } from '../../constants/moyen'; import { QuestionType } from '../questions';

export const question20: QuestionType = {
    id: 19,
    type: "multiple",
    question: "Vos parents, frère/soeur, enfants ont-ils présenté des problèmes de santé faisant partie de la liste ci dessous ? (Réponses multiples)",
    options: [
        {
            libelle: "Problèmes circulatoires/vasculaires avant 60 ans (qui touchent les vaisseaux/le sang)",
            coche: {
                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.PATCH, Moyen.ANNEAU],
                        phrase: "Contre-indication probable liée aux problèmes de santé dans votre famille",
                    }
                ]
            }
        }, {
            libelle: "Phlébite (caillot dans les vaisseaux des jambes)",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.PATCH, Moyen.ANNEAU],
                        phrase: "Contre-indication probable liée aux problèmes de santé dans votre famille",
                    }
                ]
            },
        }, {
            libelle: "Embolie pulmonaire (caillot dans les vaisseaux des poumons)",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.PATCH, Moyen.ANNEAU],
                        phrase: "Contre-indication probable liée aux problèmes de santé dans votre famille",
                    }
                ]
            },
        }, {
            libelle: "Accident vasculaire cérébral (AVC) avant 60 ans",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.PATCH, Moyen.ANNEAU],
                        phrase: "Contre-indication probable liée aux problèmes de santé dans votre famille",
                    }
                ]
            },
        }, {
            libelle: "Infarctus du myocarde (attaque cardiaque) avant 60 ans chez les femmes et 50 ans chez les hommes",
            coche: {

                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.PATCH, Moyen.ANNEAU],
                        phrase: "Contre-indication probable liée aux problèmes de santé dans votre famille",
                    }
                ]
            },
        }, {
            libelle: "Je ne sais pas",
            coche: {}
        }, {
            libelle: "Aucun",
            coche: {},
        }
    ]
}
