import { QuestionType } from '../questions';

export const question5: QuestionType = {
    id: 4,
    type: "radio",
    question: "Allaitez-vous en ce moment ?",
    options: [
        {
            libelle: "Oui",
        }, {
            libelle: "Non",
            coche: {
                goTo: {
                    questionIndex: 6
                }
            }
        }
    ]
}