import React from "react";

const SteriletCuivre: React.FC = () => (
  <div>
    <p className="moyen-section">Présentation</p>
    <p>
      Le stérilet ou DIU en cuivre est un moyen de contraception{" "}
      <span className="moyen-emph">inséré dans l’utérus</span> par un médecin ou
      une sage-femme.
    </p>
    <p>
      Il est <span className="moyen-emph">en forme de T d'environ 3 cm</span> de
      long. Il est en plastique avec des manchons en cuivre. La présence du DIU
      dans l’utérus est une des raisons pour laquelle il empêche de tomber
      enceinte. Le cuivre rend également les spermatozoïdes inactifs.
    </p>
    <p>
      Il existe des tailles différentes de stérilet. Ils se terminent par des
      fils qui permettent au professionnel de santé de retirer le DIU. Il permet
      de ne pas penser à sa contraception{" "}
      <span className="moyen-emph">pendant 5 ans</span>.
    </p>
    <p className="moyen-emph">NON :</p>
    <ul>
      <li>Il ne gène pas pendant les rapports sexuels</li>
      <li>Il ne rend pas “stérile”</li>
      <li>Il n'est pas réservé qu’aux femmes ayant déjà eu un enfant</li>
    </ul>
    <p className="moyen-section">Efficacité</p>
    <p>
      Son efficacité est de 99.2%. Il est efficace{" "}
      <span className="moyen-emph">dès le jour de son insertion</span>.
    </p>
    <p className="moyen-section">Comment l'obtenir ?</p>
    <p>
      Le prix varie de 30 euros à 111 euros environ mais il est{" "}
      <span className="moyen-emph">remboursé</span>. Il nécessite une
      prescription par un médecin ou une sage-femme.
    </p>

    <p className="moyen-section">Points positifs</p>
    <p>
      Il ne contient <span className="moyen-emph">aucune hormone</span>. Il n’a
      donc pas d’influence sur vos cycles habituels.
    </p>
    <p className="moyen-section">Points négatifs</p>
    <p>
      Il peut <span className="moyen-emph">augmenter le flux des règles</span>{" "}
      et <span className="moyen-emph">augmenter les douleurs de règles</span>.
      Il n’est donc pas conseillé si habituellement vos règles sont abondantes
      ou douloureuses.
    </p>
  </div>
);

export default SteriletCuivre;
