
import { Moyen } from '../../constants/moyen'; import { QuestionType } from '../questions';

export const question15: QuestionType = {
    id: 14,
    type: "multiple",
    question: "Vous ne souhaitez pas : (réponses multiples)",
    options: [
        {
            libelle: "Utiliser d'hormones",
            coche: {
                avantages: [
                    {
                        moyens: [Moyen.STERILET_CUIVRE, Moyen.PRESERVATIF_F, Moyen.PRESERVATIF_M, Moyen.CAPE, Moyen.DIAPHRAGME, Moyen.STERILISATION, Moyen.NATURELLES],
                        phrase: "Ne contient pas d'hormone",
                    },
                ],
                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.PATCH, Moyen.ANNEAU],
                        phrase: "Contient des hormones",
                    }, {
                        moyens: [Moyen.PILULE_M, Moyen.IMPLANT, Moyen.STERILET_HORMONAL],
                        phrase: "Contient une hormone",
                    }
                ]
            }
        }, {
            libelle: "Y penser avant un rapport sexuel",
            coche: {
                inconvenients: [
                    {
                        moyens: [Moyen.DIAPHRAGME, Moyen.CAPE, Moyen.PRESERVATIF_F],
                        phrase: "A penser avant le rapport sexuel",
                    }
                ]
            }
        }, {
            libelle: "Y penser pendant le rapport sexuel",
            coche: {
                inconvenients: [
                    {
                        moyens: [Moyen.PRESERVATIF_M],
                        phrase: "A penser pendant le rapport sexuel",
                    }
                ]
            }
        }, {
            libelle: "Toucher votre sexe",
            coche: {
                inconvenients: [
                    {
                        moyens: [Moyen.PRESERVATIF_M, Moyen.PRESERVATIF_F, Moyen.DIAPHRAGME, Moyen.CAPE, Moyen.ANNEAU],
                        phrase: "Nécessite de toucher son sexe",
                    }
                ]
            }
        }, {
            libelle: "Limiter vos rapports sexuels à une partie du mois",
            coche: {
                inconvenients: [
                    {
                        moyens: [Moyen.NATURELLES],
                        phrase: "Rapport sexuel limité à une partie du mois",
                    }
                ]
            }
        }, {
            libelle: "Qu'on le sente pendant les rapports sexuels",
            coche: {
                inconvenients: [
                    {
                        moyens: [Moyen.DIAPHRAGME, Moyen.ANNEAU],
                        phrase: "Parfois senti pendant les rapports sexuels",
                    }
                ]
            }
        }, {
            libelle: "Rien de particulier parmi cette liste",
            coche: {
            }
        }
    ]
}
