import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useState } from "react";
import QuestionnaireLink from "../components/QuestionnaireLink";

const TestIntroduction: React.FC = () => {
  const [isLoading, setLoading] = useState(false);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Test</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className="homepage ion-padding-horizontal ion-text-center">
        <p>
          Choisir une méthode de contraception est difficile. Il n’existe pas de
          méthode de contraception parfaite. Ainsi parmi les différentes
          méthodes existantes, il faut trouver celle qui vous convient le mieux
          en fonction de vos envies, de votre vie et de votre santé.
        </p>
        <p className="emph-danger">
          À l'issue de ce test, toutes les contraceptions vont s'afficher dans
          l'ordre d'efficacité pratique considéré par Santé Publique France et
          non en fonction de vos réponses. Par contre, les avantages et
          inconvénients s'afficheront pour chacune des contraceptions selon vos
          réponses au test.
        </p>
        <p>
          Ces résultats sont là pour vous aider à réfléchir à votre méthode de
          contraception. Pensez à en parler lors de votre prochaine consultation
          de contraception.
        </p>
        <IonButton
          onClick={() => setLoading(true)}
          routerLink="/test"
          routerDirection="root"
        >
          Commencer le test
          {isLoading && <IonSpinner name="crescent" />}
        </IonButton>
        <p>
          Une fois le test effectué, pour m'aider dans ma thèse, vous pouvez
          répondre à ce questionnaire sur l'application :<QuestionnaireLink />
        </p>
      </IonContent>
    </IonPage>
  );
};

export default TestIntroduction;
