import React from "react";

const Sterilisation: React.FC = () => (
  <>
    <p className="moyen-section">Présentation</p>
    <p>
      La stérilisation à visée contraceptive concerne les femmes qui{" "}
      <span className="moyen-emph">
        ne souhaitent pas ou plus avoir d’enfant
      </span>
      . Les méthodes s’appellent : ligature des trompes, électrocoagulation, ou
      de clips.
    </p>
    <p>
      Elles consistent à “boucher” ou "couper" les trompes de Fallope. Ces
      trompes sont l’intermédiaire entre l’ovaire et l’utérus. Si elles sont
      “bouchées” ou "coupées", les spermatozoïdes ne peuvent pas rencontrer
      l’ovocyte (sécrété par l’ovaire) donc la fécondation est impossible. La
      fécondation est la rencontre entre l’ovocyte et le spermatozoïde qui
      formeront le futur bébé.
    </p>
    <p>
      C’est une{" "}
      <span className="moyen-emph">chirurgie sous anesthésie générale</span>.
    </p>
    <p className="moyen-section">Comment l'obtenir ?</p>
    <p>
      Pour réaliser cette méthode, une consultation avec un{" "}
      <span className="moyen-emph">chirurgien</span> est nécessaire et il faut
      un attendre un délai minimum de 4 mois pour réfléchir. Il n’y a pas de
      conditions d’âge (mais personne majeure exclusivement). On peut le faire
      même si on n'a pas d’enfant.
    </p>
  </>
);

export default Sterilisation;
