import React from "react";

const Diaphragme: React.FC = () => (
  <>
    <p className="moyen-section">Présentation</p>
    <p>
      Le diaphragme ressemble à <span className="moyen-emph">une coupelle</span>{" "}
      qui se glisse dans le vagin, avant un rapport, pour empêcher le passage
      des spermatozoïdes.
    </p>
    <p>
      Il peut être posé jusqu’à 2h{" "}
      <span className="moyen-emph">avant le rapport sexuel</span> et doit être
      laissé en place 8h après le rapport. Il peut être utilisé plusieurs fois
      mais doit être lavé entre chaque utilisation (à l’eau et au savon neutre).
    </p>
    <p className="moyen-section">Efficacité</p>
    <p>
      Si on l’utilise parfaitement (sans erreur de manipulation), il est
      efficace à 94%. Dans la vie de tous les jours, l’efficacité est de{" "}
      <span className="moyen-emph">88%</span> (plus d’1 femme sur 10 tombe
      enceinte avec ce moyen).
    </p>
    <p>
      Il est conseillé d’utiliser des{" "}
      <span className="moyen-emph">spermicides</span> en association pour une
      meilleure efficacité. (voir “Les spermicides” pour plus ‘informations)
    </p>
    <p className="moyen-section">Comment l'obtenir ?</p>
    <p>
      Il nécessite une <span className="moyen-emph">prescription</span> par un
      médecin ou une sage-femme pour l’obtenir en pharmacie. Le diaphragme coûte
      environ 33 euros. Il est très{" "}
      <span className="moyen-emph">peu remboursé</span> (environ 3.14 euros).
    </p>
  </>
);

export default Diaphragme;
