import {
  IonButtons,
  IonContent,
  IonHeader,
  IonList,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { concat, orderBy, tail } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { MoyenType } from "../actions/types";
import AnswerCardElement from "../components/AnswerCardElement/answerCardElement";
import MoyenModal from "../components/moyenModal/MoyenModal";
import SatisfactionCard from "../components/SatifactionCard/satisfactionCard";
import { QuestionsContext } from "../contexts/contexts";
import moyensData from "../data/moyens";
import useModal from "../hooks/useModal";

const Answers: React.FC = () => {
  const {
    state: {
      reponses: { moyens },
    },
  } = useContext(QuestionsContext);
  const [modalShowing, showModal, hideModal] = useModal();
  const [selectedMoyen, setSelectedMoyen] = useState<MoyenType>(moyensData[0]);
  const [reponsesElmts, setReponsesElmts] = useState<Array<JSX.Element>>();

  const onCardClick = useCallback(
    (moyen: MoyenType) => {
      setSelectedMoyen(moyen);
      showModal();
    },
    [setSelectedMoyen, showModal]
  );

  useEffect(() => {
    const cards = orderBy(moyens, ["moyen.indice"], ["desc"])
      .filter((moy) => moy.moyen.displayInResult)
      .map((moyenRep, i) => (
        <AnswerCardElement
          reponse={moyenRep}
          key={`moyen-${i}`}
          onClick={onCardClick}
        />
      ));

    setReponsesElmts(
      concat(
        cards[0],
        <SatisfactionCard key="satisfaction-card" />,
        tail(cards)
      )
    );
  }, [moyens, onCardClick]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Réponses</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="ion-padding-horizontal ion-text-center">
          <h1 className="quattrocento-bold">
            L’affichage ci-dessous classe les différents moyens de
            contraceptions selon leur efficacité pratique.
          </h1>
          <p>
            La première contraception est celle qui présente le moins de risque
            de grossesse dans la vie de tous les jours (en comptant les erreurs
            de pratique, les oublis, etc). La dernière est celle où le risque de
            grossesse est le plus grand en pratique.
          </p>
          <p className="emph-danger">
            ATTENTION, l’ordre d’affichage des contraceptions n’est pas en
            fonction de ce que vous avez répondu mais en fonction de l’ordre
            d’efficacité pratique ! Les avantages et les inconvénients
            s'affichent, eux, en fonction de vos réponses.
          </p>
        </div>
        <IonList>{reponsesElmts}</IonList>
        <MoyenModal
          closeModal={hideModal}
          moyen={selectedMoyen}
          showModal={modalShowing}
        />
      </IonContent>
    </IonPage>
  );
};

export default Answers;
