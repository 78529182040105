import React from "react";

const ContraceptionUrgenceHormonale: React.FC = () => (
  <>
    <p className="moyen-section">Présentation</p>
    <p>
      La contraception d’urgence hormonale est un comprimé{" "}
      <span className="moyen-emph">contenant une hormone</span>. Cette hormone
      va retarder l’ovulation et empêcher la rencontre entre le spermatozoïde et
      l’ovocyte nécessaire pour former le futur bébé.
    </p>
    <ul>
      <li>
        un comprimé contenant du “lévonorgestrel” (de la progestérone) peut être
        pris <span className="moyen-emph">jusqu’à 72 h</span> après le rapport
        sexuel à risque
      </li>
      <li>
        un comprimé contenant de “l’ulipristal acétate” peut être pris
        <span className="moyen-emph"> jusqu’à 120 h</span> après un rapport à
        risque.
      </li>
    </ul>
    <p>
      Si le comprimé est <span className="moyen-emph">vomi</span> dans les 3h
      suite à la prise, il faut en prendre un nouveau.
    </p>
    <p>
      Certains médicaments que l’on prend tous les jours peuvent diminuer son
      effet. (voir avec un professionnel de santé).
    </p>
    <p>
      <span className="moyen-emph">Attention</span> la contraception d’urgence
      ne protège que pour un rapport à risque, elle ne protège pas après.
      <br />
      Si vous utilisez la pilule, il faut donc utiliser des préservatifs
      jusqu’aux prochaines règles sans interrompre la plaquette
    </p>
    <p className="moyen-section">Les effets indésirables</p>
    <p>Ils peuvent donner : </p>
    <ul className="moyen-emph">
      <li>des nausées</li>
      <li>des maux de tête</li>
      <li>des douleurs abdominales</li>
      <li>des vertiges</li>
      <li>une petite perte de sang</li>
    </ul>
    <p>Les règles habituelles peuvent être plus tôt ou plus tard. </p>
    <p>
      <span className="moyen-emph">NON</span>, ils :
    </p>
    <ul>
      <li>ne rendent pas stérile</li>
      <li>
        n’augmentent pas le risque de fausse couche ou de grossesse mal placée
        (grossesse extra utérine)
      </li>
    </ul>
    <p>
      Si vous tombez enceinte malgré la prise du comprimé et que vous souhaitez
      garder la grossesse, le comprimé au lévonorgestrel n’a pas de risque sur
      le futur bébé. Le risque concernant l’ulipristal acétate n’est pas connu.
    </p>
    <p>
      Pour les patientes qui <span className="moyen-emph">allaitent</span>, il
      faut attendre un délai de quelques heures à quelques jours entre la prise
      du comprimé et la prochaine tétée. Ces délais sont indiqués sur la notice
      du comprimé
    </p>
    <p className="moyen-section">Comment l’obtenir ?</p>
    <p>
      Les <span className="moyen-emph">mineurs</span> peuvent l’avoir{" "}
      <span className="moyen-emph">gratuitement</span> et de manière{" "}
      <span className="moyen-emph">anonyme</span> en pharmacie, auprès de
      l’infirmière scolaire et dans un Centre de Planification et d’Education
      Familiale.
    </p>
    <p>
      Pour les <span className="moyen-emph">étudiantes</span>, les services
      universitaires de médecine préventive peuvent le procurer gratuitement.
    </p>
    <p>
      Sinon, le comprimé peut s’obtenir{" "}
      <span className="moyen-emph">sans ordonnance</span> en pharmacie.
    </p>
    <p>Le comprimé au lévonorgestrel coûte environ 7 euros.</p>
    <p>Le comprimé à l’ulipristal acétate coûte environ 19 euros.</p>
    <p>
      Si vous avez une ordonnance, les 2 types de comprimés{" "}
      <span className="moyen-emph">sont remboursés</span>.
    </p>
  </>
);

export default ContraceptionUrgenceHormonale;
