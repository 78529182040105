import React from "react";

const Patch: React.FC = () => (
  <>
    <p className="moyen-section">Présentation</p>
    <p>
      Le patch est comme un{" "}
      <span className="moyen-emph">timbre qui se colle sur la peau</span>. Il
      contient deux hormones : l'<span className="moyen-emph">oestrogène</span>{" "}
      et la <span className="moyen-emph">progestérone</span>. On peut le placer
      sur le ventre, les épaules ou encore le bas du dos (seuls les seins sont à
      éviter).
    </p>
    <p>
      Il protège pendant <span className="moyen-emph">4 semaines</span>. Il faut
      le changer <span className="moyen-emph">chaque semaine</span> pendant 3
      semaines. La quatrième semaine, il ne faut pas mettre de patch, ce qui
      provoque l’arrivée des règles. Puis, un nouveau patch se remet pendant 3
      semaines et ainsi de suite.
    </p>
    <p className="moyen-section">Efficacité</p>
    <p>
      S’il est utilisé sans erreur, son efficacité est supérieure à{" "}
      <span className="moyen-emph">99.7%</span>. Si on prend en compte les
      erreurs (comme les oublis), son efficacité est de{" "}
      <span className="moyen-emph">91%</span>.
    </p>
    <p>
      Pour la première utilisation, il est efficace{" "}
      <span className="moyen-emph">dès le début de la pose</span> s'il est posé
      le premier jour des règles sinon il sera efficace{" "}
      <span className="moyen-emph">au bout de 7 jours</span>.
    </p>

    <p className="moyen-section">Comment l'obtenir ?</p>
    <p>
      Il n’est <span className="moyen-emph">pas remboursé</span>. Une boîte de 3
      coûte environ <span className="moyen-emph">15 euros</span>. Une{" "}
      <span className="moyen-emph">ordonnance</span> par un médecin ou une
      sage-femme est nécessaire pour l’obtenir en pharmacie.
    </p>

    <p className="moyen-section">Les points positifs</p>
    <p>Il permet de: </p>
    <ul className="moyen-emph">
      <li>Régulariser les cycles</li>
      <li>Diminuer les douleurs de règles</li>
      <li>Diminuer le flux des règles</li>
    </ul>
    <p>
      Il faut y penser seulement{" "}
      <span className="moyen-emph">1 fois par semaine</span>. <br />
      L’avoir sur soi permet de s’en rappeler. <br />
      Il est <span className="moyen-emph">
        discret et facile à utiliser
      </span>. <br />
      Il peut être pratique pour les personnes qui se déplacent beaucoup et en
      cas de décalage horaire.
    </p>

    <p className="moyen-section">Les points négatifs</p>
    <p>Il peut parfois (comme la pilule oestroprogestative):</p>
    <ul className="moyen-emph">
      <li>Augmenter l'appétit</li>
      <li>Donner des sautes d'humeur</li>
      <li>Modifier le désir sexuel</li>
      <li>Donner des douleurs aux seins</li>
      <li>Maux de tête</li>
      <li>Augmenter l'acné</li>
    </ul>
    <p>
      L’utilisation d’une patch augmente le risque de thrombose (bouchon dans
      les vaisseaux). Ce risque augmente d’autant plus{" "}
      <span className="moyen-emph">si vous fumez</span>.
    </p>
    <p>
      Il{" "}
      <span className="moyen-emph">
        ne peut pas être utilisé par tout le monde
      </span>{" "}
      et certains médicaments pris tous les jours peuvent diminuer son
      efficacité (en parler avec un professionnel de santé).
    </p>
    <p className="moyen-section">En cas d'oubli</p>
    <p>
      <span className="moyen-emph">
        Si le patch se décolle ou si on oublie de le changer pendant moins de 24
        heures
      </span>
      , recollez un nouveau patch. Il faudra retirer le nouveau patch à la date
      initialement prévue. <u>Exemple</u> : si vous changez votre patch tous les
      lundis, il faudra changer le patch le lundi suivant comme prévu. Il faudra
      toutefois faire un nouveau cycle de 3 patchs à la suite.{" "}
      <span className="moyen-emph">Vous restez protégée.</span>
    </p>
    <p>
      <span className="moyen-emph">
        Si le patch se décolle ou si on oublie de le changer pendant plus de 24
        heures
      </span>
      , recollez un nouveau patch. Le jour de mise en place du nouveau patch
      devient le nouveau “premier jour” du cycle. Il faudra donc faire un
      nouveau cycle de 3 patchs.
      <br />
      <u>Exemple</u> : si vous deviez changer de patch le lundi et vous vous en
      rendez compte le mercredi suivant, vous collez un nouveau patch ce
      mercredi et il faudra changer votre patch les 3 mercredis suivant. Vous
      avez changé de jours de changement de patch.
      <br />
      <span className="moyen-color">
        Dans ce cas, vous n’êtes plus protégée
      </span>{" "}
      donc il faudra pendant les 7 jours suivant l’oubli, utiliser une
      contraception mécaniques (les préservatifs).
    </p>
    <p>
      <span className="moyen-color">
        Si vous avez eu un rapport sexuel dans les 5 jours précédant l’oubli
      </span>
      , il faut prendre une contraception d’urgence. (Voir "La contraception
      d’urgence")
    </p>
  </>
);

export default Patch;
