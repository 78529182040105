import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonSlide,
  IonSlides,
  IonTitle,
  IonToolbar,
  useIonViewDidLeave,
} from "@ionic/react";
import React, { useContext, useRef } from "react";
import { useHistory } from "react-router";
import SlideContent from "../components/questions/SlideContent";
import { QuestionsContext } from "../contexts/contexts";
import "./TestPage.css";

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  allowTouchMove: false,
};

const TestSlides: React.FC = () => {
  const history = useHistory();

  const slideRef = useRef<HTMLIonSlidesElement>(null);

  const slideTo = async (slideId: number): Promise<void> => {
    const end = await slideRef.current?.isEnd();
    if (end) {
      history.push("/reponses");
      setTimeout(() => {
        slideRef.current?.slideTo(0);
      }, 500);
    } else {
      await slideRef.current?.slideTo(slideId);
    }
  };

  const {
    state: { questions },
  } = useContext(QuestionsContext);

  useIonViewDidLeave(() => {
    slideRef.current?.slideTo(0);
  });

  const slides = questions.map((question, index) => (
    <IonSlide key={`slide-${index}`}>
      <SlideContent
        key={`slide-content_${index}`}
        slideTo={slideTo}
        question={question}
      />
    </IonSlide>
  ));

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Test</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonSlides pager options={slideOpts} ref={slideRef}>
          {slides}
        </IonSlides>
      </IonContent>
    </IonPage>
  );
};

export default TestSlides;
