
import { Moyen } from '../../constants/moyen'; import { QuestionType } from '../questions';

export const question18: QuestionType = {
    id: 17,
    type: "radio",
    question: "Avez-vous un train de vie qui rend difficile de prendre une contraception tous les jours à la même heure ? (décalage horaire, horaires de nuit/de jour changeant)",
    options: [
        {
            libelle: "Oui",
            coche: {
                avantages: [
                    {
                        moyens: [Moyen.IMPLANT, Moyen.STERILET_CUIVRE, Moyen.STERILET_HORMONAL, Moyen.PATCH, Moyen.ANNEAU],
                        phrase: "Pratique en cas de train de vie avec décalage horaire ou horaires de nuit et jour changeants ",
                    }
                ],
                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.PILULE_O],
                        phrase: "Contraignant en cas de train de vie avec décalage horaire ou horaires de nuit et jour changeants ",
                    }
                ]
            }
        }, {
            libelle: "Non",
            coche: {}
        }
    ]
}
