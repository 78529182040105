import {
    accessibilityOutline,
    bookOutline,
    bookSharp,
    homeOutline,
    homeSharp,
    mailUnreadOutline,
    mailUnreadSharp,
    medicalOutline,
    newspaperOutline,
    newspaperSharp,
    paperPlaneOutline,
    paperPlaneSharp,
} from "ionicons/icons";

type AppPageMenu = {
    iosIcon?: string;
    mdIcon?: string;
    url: string;
    title: string;
    menuDisplay: boolean;
};


type AppPages = {
    [key: string]: AppPageMenu
}

const appPages: AppPages = {
    accueil: {
        title: "Accueil",
        url: "/accueil",
        iosIcon: homeOutline,
        mdIcon: homeSharp,
        menuDisplay: true
    },
    testIntroduction: {
        title: "Test",
        url: "/test-introduction",
        iosIcon: paperPlaneOutline,
        mdIcon: paperPlaneSharp,
        menuDisplay: true
    },
    test: {
        title: "Test",
        url: "/test",
        menuDisplay: false
    },
    moyens: {
        title: "Informations",
        url: "/moyens",
        iosIcon: bookOutline,
        mdIcon: bookSharp,
        menuDisplay: true
    },
    reponses: {
        title: "Les réponses",
        url: "/reponses",
        menuDisplay: false
    },
    contraceptionUrgence: {
        title: "La contraception d'urgence",
        url: "/contraception-urgence",
        iosIcon: medicalOutline,
        mdIcon: medicalOutline,
        menuDisplay: true
    },
    contraceptionAccouchement: {
        title: "Après l'accouchement",
        url: "/contraception-accouchement",
        iosIcon: accessibilityOutline,
        mdIcon: accessibilityOutline,
        menuDisplay: true
    },
    avis: {
        title: "Votre avis",
        url: "/avis",
        iosIcon: mailUnreadOutline,
        mdIcon: mailUnreadSharp,
        menuDisplay: true
    },
    apropos: {
        title: "A propos",
        url: "/apropos",
        iosIcon: newspaperOutline,
        mdIcon: newspaperSharp,
        menuDisplay: true
    },
};

export default appPages;