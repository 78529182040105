
import { Moyen } from '../../constants/moyen'; import { QuestionType } from '../questions';

export const question16: QuestionType = {
    id: 15,
    type: "multiple",
    question: "Vous préférez que la contraception : (réponses multiples)",
    options: [
        {
            libelle: "Soit placée dans l'utérus",
            coche: {
                avantages: [
                    {
                        moyens: [Moyen.STERILET_CUIVRE, Moyen.STERILET_HORMONAL],
                        phrase: "Placé dans l'utérus",
                    }
                ],
            },
            noncoche: {
                inconvenients: [
                    {
                        moyens: [Moyen.STERILET_CUIVRE, Moyen.STERILET_HORMONAL],
                        phrase: "Placé dans l'utérus",
                    },
                ]
            }
        }, {
            libelle: "Soit placée sous la peau",
            coche: {
                avantages: [
                    {
                        moyens: [Moyen.IMPLANT],
                        phrase: "Placé sous la peau",
                    },
                ],
            },
            noncoche: {
                inconvenients: [
                    {
                        moyens: [Moyen.IMPLANT],
                        phrase: "Placé sous la peau",
                    },
                ]
            }
        }, {
            libelle: "Soit placée sur la peau",
            coche: {
                avantages: [
                    {
                        moyens: [Moyen.PATCH],
                        phrase: "Placé sur la peau",
                    }
                ],
            },
            noncoche: {
                inconvenients: [
                    {
                        moyens: [Moyen.PATCH],
                        phrase: "Placé sur la peau",
                    },
                ]
            }
        }, {
            libelle: "Soit pris par la bouche",
            coche: {
                avantages: [
                    {
                        moyens: [Moyen.PILULE_M, Moyen.PILULE_O],
                        phrase: "A prendre par la bouche",
                    },
                ],
            },
            noncoche: {
                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_M, Moyen.PILULE_O],
                        phrase: "A prendre par la bouche",
                    },
                ]
            }
        }, {
            libelle: "Soit mis à l'intérieur du vagin",
            coche: {
                avantages: [
                    {
                        moyens: [Moyen.ANNEAU, Moyen.CAPE, Moyen.DIAPHRAGME, Moyen.PRESERVATIF_F],
                        phrase: "A mettre dans le vagin",
                    }
                ],
            },
            noncoche: {
                inconvenients: [
                    {
                        moyens: [Moyen.ANNEAU, Moyen.CAPE, Moyen.DIAPHRAGME, Moyen.PRESERVATIF_F],
                        phrase: "A mettre dans le vagin",
                    },
                ]
            }
        }, {
            libelle: "Rien de particulier",
            coche: {
            }
        }
    ]
}
