import React from "react";

const SteriletHormonal: React.FC = () => (
  <>
    <p className="moyen-section">Présentation</p>
    <p>
      Le stérilet ou DIU hormonal est un moyen de contraception{" "}
      <span className="moyen-emph">inséré dans l’utérus</span> par un médecin ou
      une sage-femme.
    </p>
    <p>
      Il est <span className="moyen-emph">en forme de T d'environ 3 cm</span> de
      long. Il contient une hormone : la
      <span className="moyen-emph"> progestérone</span>.
    </p>
    <p>
      Elle se diffuse au niveau de l’utérus et autour. La présence du DIU dans
      l’utérus est une des raisons pour laquelle il empêche de tomber enceinte.
      L'hormone a également un rôle. Elle épaissit les sécrétions du col de
      l’utérus (l’entrée de l'utérus), empêchant les spermatozoïdes de passer.
      Elle a aussi un effet sur la muqueuse de l'utérus (une partie de l'utérus)
      qui est normalement nécessaire pour tomber enceinte.
    </p>
    <p>
      Il existe des tailles différentes de stérilet. Il se termine par des fils
      qui permettent au professionnel de santé de retirer le DIU. Il permet de
      ne pas penser à sa contraception pendant{" "}
      <span className="moyen-emph">5 à 10 ans</span>.
    </p>
    <p className="moyen-emph">NON:</p>
    <ul className="moyen-emph">
      <li>Il ne gène pas pendant les rapports sexuels</li>
      <li>Il ne rend pas stérile</li>
      <li>Il n'est pas réservé qu’aux femmes ayant déjà eu un enfant</li>
    </ul>
    <p className="moyen-section">Efficacité</p>
    <p>
      Il est efficace{" "}
      <span className="moyen-emph">
        immédiatement après sa pose s’il est posé dans les 7 premiers jours du
        cycle
      </span>
      . Son efficacité est de <span className="moyen-emph">99.8%</span>.
    </p>
    <p className="moyen-section">Comment l'obtenir ?</p>
    <p>
      Le prix varie de 30 euros à 111 euros environ mais il est{" "}
      <span className="moyen-emph">remboursé</span>. Il nécessite une
      prescription par un médecin ou une sage-femme et doit être posé par un
      médecin ou une sage-femme.
    </p>
    <p className="moyen-section">Points positifs</p>
    <p>Il permet aussi:</p>
    <ul className="moyen-emph">
      <li>d'arrêter les règles ou de les diminuer. </li>
      <li>de stopper ou diminuer les douleurs de règles</li>
    </ul>
    <p className="moyen-section">Points négatifs</p>
    <p>Il peut parfois provoquer:</p>
    <ul className="moyen-emph">
      <li>Des petits saignements ponctuels (spotting)</li>
      <li>Augmenter l'acné</li>
      <li>Augmenter l'appétit</li>
      <li>Provoquer des douleurs aux seins</li>
      <li>Des sautes d'humeurs</li>
      <li>Une modification du désir sexuel</li>
    </ul>
    <p>
      A noter, que ces effets indésirables seraient moins retrouvés par rapport
      aux pilules ou à l’implant car l’hormone est diffusée plus localement.
    </p>
  </>
);

export default SteriletHormonal;
