export enum Moyen {
    PILULE_O = "Pilule combinée",
    PILULE_M = "Pilule micro-progestative",
    PATCH = "Le patch contraceptif",
    ANNEAU = "L'anneau vaginal",
    IMPLANT = "L'implant",
    STERILET_HORMONAL = "Dispositif Intra-Utérin hormonal",
    STERILET_CUIVRE = "Dispositif Intra-Utérin en cuivre",
    PRESERVATIF_M = "Le préservatif externe",
    PRESERVATIF_F = "Le préservatif interne",
    DIAPHRAGME = "Le diaphragme",
    CAPE = "La cape cervicale",
    NATURELLES = "Les méthodes naturelles",
    STERILISATION = "La stérilisation à visée contraceptive",
}

export enum MoyenNonClasse {
    PROGESTATIFS = "Les progestatifs injectables",
    CONTRACEPTION_URGENCE = "La contraception d’urgence",
    SPERMICIDES = "Les spermicides",
}