import React from 'react';
import { Moyen, MoyenNonClasse } from '../constants/moyen';

enum QuestionType {
  MULTIPLE, RADIO, DATE, NUMERIC
}

type ReponseType = {
  moyen: MoyenType;
  indice: number;
  avantages: Array<String>;
  inconvenients: Array<String>;
}

type ReponseStateType = {
  moyens: Array<ReponseType>;
}

type MoyenType = {
  titre: Moyen | MoyenNonClasse;
  composant: React.FC;
  img: string;
  displayInResult: boolean;
  indice: number;
};

enum ReponseActionType {
  ANSWER_MULTIPLE = "ANSWER_MULTIPLE",
  ANSWER_GENERALE = "ANSWER_GENERALE",
  ANSWER_RADIO = "ANSWER_RADIO",
  RESET = "RESET_STATE",
}

export { ReponseActionType, QuestionType };
export type { ReponseStateType, MoyenType, ReponseType };

