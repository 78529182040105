import React, { createContext, useMemo, useReducer } from "react";
import { ReponseActions } from "../actions/questionActions";
import { ReponseStateType } from "../actions/types";
import { Moyen } from "../constants/moyen";
import moyens from "../data/moyens";
import { QuestionType, questions } from "../data/questions";
import { questionReducer, reponseReducer } from "../reducers/reducers";

type InitialStateType = {
  questions: Array<QuestionType>;
  reponses: ReponseStateType;
};

const initialState = () => {
  return {
    questions: questions,
    reponses: {
      moyens: moyens
        .filter((moy) => Object.values(Moyen).includes(moy.titre as Moyen))
        .map((moyen) => ({
          moyen: moyen,
          indice: moyen.indice,
          avantages: [],
          inconvenients: [],
        })),
      props: {},
    },
  };
};

const QuestionsContext = createContext<{
  state: InitialStateType;
  dispatch: React.Dispatch<any>;
}>({ state: initialState(), dispatch: () => null });

const mainReducer = (state: InitialStateType, action: ReponseActions) => ({
  questions: questionReducer(state.questions, action),
  reponses: reponseReducer(state.reponses, action),
});

const logger = (reducer: React.Reducer<any, any>) => {
  const reducerWithLogger = (state: Object, action: string) => {
    // console.log("%cPrevious State:", "color: #9E9E9E; ", state);
    // console.log("%cAction:", "color: #00A7F7;", action);
    // console.log("%cNext State:", "color: #47B04B;", reducer(state, action));
    return reducer(state, action);
  };
  return reducerWithLogger;
};

const loggedMainReducer = logger(mainReducer);

const QuestionsProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(loggedMainReducer, initialState());

  const contextValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <QuestionsContext.Provider value={contextValue}>
      {children}
    </QuestionsContext.Provider>
  );
};

export { QuestionsContext, QuestionsProvider };
