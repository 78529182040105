
import { Moyen } from '../../constants/moyen'; import { QuestionType } from '../questions';

export const question7: QuestionType = {
    id: 6,
    type: "radio",
    question: "Est-ce qu’aujourd’hui tomber enceinte serait acceptable pour vous ?",
    options: [
        {
            libelle: "Non pas du tout",
            coche: {
                avantages: [
                    {
                        moyens: [Moyen.ANNEAU],
                        phrase: "Protège théoriquement à 99.7% sans oubli ou erreur",
                    },
                    {
                        moyens: [Moyen.STERILET_CUIVRE],
                        phrase: "Protège théoriquement à 99.4%",
                    }, {
                        moyens: [Moyen.STERILET_HORMONAL],
                        phrase: "Protège théoriquement à 99.8%",
                    }, {
                        moyens: [Moyen.IMPLANT],
                        phrase: "Contraception la plus efficace, protège à 99.9%",
                    }, {
                        moyens: [Moyen.PATCH],
                        phrase: "Protège théoriquement à 99.7% sans oubli ou erreur",
                    }, {
                        moyens: [Moyen.PILULE_O, Moyen.PILULE_M],
                        phrase: "Protège théoriquement à 99.7% sans oubli ou erreur",
                    }, {
                        moyens: [Moyen.STERILISATION],
                        phrase: "Quasiment aucun risque de tomber enceinte",
                    }
                ],
                inconvenients: [
                    {
                        moyens: [Moyen.NATURELLES],
                        phrase: "Très à risque de tomber enceinte avec cette méthode en pratique",
                    }, {
                        moyens: [Moyen.CAPE],
                        phrase: "16% de risque de tomber enceinte avec cette méthode en comptant les erreurs",
                    }, {
                        moyens: [Moyen.DIAPHRAGME],
                        phrase: "12% de risque de tomber enceinte avec cette méthode en comptant les erreurs",
                    }, {
                        moyens: [Moyen.PRESERVATIF_M, Moyen.PRESERVATIF_F],
                        phrase: "Environ 2 risques sur 10 de tomber enceinte en comptant les erreurs ou les accidents",
                    }
                ]
            }
        }, {
            libelle: "Plutôt pas",
            coche: {
                avantages: [
                    {
                        moyens: [Moyen.ANNEAU],
                        phrase: "Protège théoriquement à 99.7% sans oubli ou erreur",
                    },
                    {
                        moyens: [Moyen.STERILET_CUIVRE],
                        phrase: "Protège théoriquement à 99.4%",
                    }, {
                        moyens: [Moyen.STERILET_HORMONAL],
                        phrase: "Protège théoriquement à 99.8%",
                    }, {
                        moyens: [Moyen.IMPLANT],
                        phrase: "Contraception la plus efficace, protège à 99.9%",
                    }, {
                        moyens: [Moyen.PATCH],
                        phrase: "Protège théoriquement à 99.7% sans oubli ou erreur",
                    }, {
                        moyens: [Moyen.PILULE_O, Moyen.PILULE_M],
                        phrase: "Protège théoriquement à 99.7% sans oubli ou erreur",
                    }
                ],
                inconvenients: [
                    {
                        moyens: [Moyen.NATURELLES],
                        phrase: "Très à risque de tomber enceinte avec cette méthode en pratique",
                    }, {
                        moyens: [Moyen.CAPE],
                        phrase: "16% de risque de tomber enceinte avec cette méthode en comptant les erreurs",
                    }, {
                        moyens: [Moyen.DIAPHRAGME],
                        phrase: "12% de risque de tomber enceinte avec cette méthode en comptant les erreurs",
                    }, {
                        moyens: [Moyen.PRESERVATIF_M, Moyen.PRESERVATIF_F],
                        phrase: "Environ 2 chances sur 10 de tomber enceinte en comptant les erreurs ou les accidents",
                    }
                ]
            }
        }, {
            libelle: "Oui",
            coche: {
                avantages: [
                    {
                        moyens: [Moyen.ANNEAU],
                        phrase: "Protège théoriquement à 99.7% sans oubli ou erreur",
                    },
                    {
                        moyens: [Moyen.STERILET_CUIVRE],
                        phrase: "Protège théoriquement à 99.4%",
                    }, {
                        moyens: [Moyen.STERILET_HORMONAL],
                        phrase: "Protège théoriquement à 99.8%",
                    }, {
                        moyens: [Moyen.IMPLANT],
                        phrase: "Contraception la plus efficace, protège à 99.9%",
                    }, {
                        moyens: [Moyen.PATCH],
                        phrase: "Protège théoriquement à 99.7% sans oubli ou erreur",
                    }, {
                        moyens: [Moyen.PILULE_O, Moyen.PILULE_M],
                        phrase: "Protège théoriquement à 99.7% sans oubli ou erreur",
                    }
                ],
                inconvenients: [
                    {
                        moyens: [Moyen.NATURELLES],
                        phrase: "Très à risque de tomber enceinte avec cette méthode en pratique",
                    }, {
                        moyens: [Moyen.CAPE],
                        phrase: "16% de risque de tomber enceinte avec cette méthode en comptant les erreurs",
                    }, {
                        moyens: [Moyen.DIAPHRAGME],
                        phrase: "12% de risque de tomber enceinte avec cette méthode en comptant les erreurs",
                    }, {
                        moyens: [Moyen.PRESERVATIF_M, Moyen.PRESERVATIF_F],
                        phrase: "Environ 2 risques sur 10 de tomber enceinte en comptant les erreurs ou les accidents",
                    }
                ]
            }
        }, {
            libelle: "Je ne sais pas",
            coche: {
            }
        }
    ]
}
