import React from "react";

const PreservatifF: React.FC = () => (
  <>
    <p className="moyen-section">Présentation</p>
    <p>
      Le préservatif interne (féminin) fonctionne comme le préservatif masculin
      en empêchant les spermatozoïdes de passer dans le vagin. C’est une{" "}
      <span className="moyen-emph">gaine</span> qui se place dans le vagin pour
      recevoir le sexe masculin au moment de la pénétration. Il peut être mis
      jusqu’à 8 heures avant le rapport sexuel.
    </p>
    <p>
      Il est très résistant. Il ne nécessite pas que l’homme se retire juste
      après avoir éjaculé. Il n’est pas fait en latex (en cas d’allergie) mais
      en nitrile ou polyuréthane.
    </p>
    <p>
      Il est le seul moyen de contraception avec le préservatif masculin{" "}
      <span className="moyen-emph">
        à protéger contre les infections sexuellement transmissibles (IST)
      </span>
      .
    </p>
    <p>
      Il peut être utilisé en complément d’une autre méthode de contraception
      (double protection contraceptive et protection contre les IST).
    </p>
    <p>
      Il doit être changé à chaque rapport sexuel, entre chaque pénétration.{" "}
      <span className="moyen-emph">
        Attention, ne jamais utiliser un préservatif interne et externe en même
        temps. Cela risquerait de les déchirer.
      </span>
    </p>
    <p className="moyen-section">Efficacité</p>
    <p>
      Son efficacité pratique (en comptant les risques de mauvaise utilisation,
      problème de pose) est de <span className="moyen-emph">79%</span>. Bien
      utilisé, il est efficace à 95%.
    </p>
    <p className="moyen-section">Comment l'obtenir ?</p>
    <p>
      Il s’achète <span className="moyen-emph">sans ordonnance</span> en
      pharmacie ou sur internet. Il n’est{" "}
      <span className="moyen-emph">pas remboursé</span> et coûte environ 9 euros
      la boîte de 3.
      <br />
      Des endroits comme le centre de planification, les centres gratuits
      d’information de dépistage et de diagnostic (CeGIDD) ou les associations
      de lutte contre le VIH en donnent gratuitement.
    </p>
  </>
);

export default PreservatifF;
