
import { Moyen } from '../../constants/moyen'; import { QuestionType } from '../questions';

export const question14: QuestionType = {
    id: 13,
    type: "radio",
    question: "Etes-vous prêtes à aller chez le médecin ou une sage-femme pour votre contraception ?",
    options: [
        {
            libelle: "Oui",
            coche: {
            }
        }, {
            libelle: "Non",
            coche: {
                avantages: [
                    {
                        moyens: [Moyen.PRESERVATIF_F, Moyen.PRESERVATIF_M, Moyen.NATURELLES],
                        phrase: "Ne nécessite pas obligatoirement une consultation avec un médecin ou une sage-femme mais fortement recommandée pour comprendre la bonne pratique de la méthode",
                    },

                ],
                inconvenients: [
                    {
                        moyens: [Moyen.PILULE_M, Moyen.PILULE_O, Moyen.ANNEAU, Moyen.PATCH, Moyen.CAPE, Moyen.DIAPHRAGME, Moyen.IMPLANT, Moyen.STERILET_CUIVRE, Moyen.STERILET_HORMONAL],
                        phrase: "Nécessite une ordonnance par un médecin ou une sage-femmme",
                    }, {
                        moyens: [Moyen.IMPLANT, Moyen.STERILET_HORMONAL, Moyen.STERILET_CUIVRE],
                        phrase: "Nécessite d'être mis en place pendant une consultation avec un médecin ou une sage-femme",
                    }, {
                        moyens: [Moyen.STERILISATION],
                        phrase: "Nécessite une consultation avec un médecin et une chirurgie ",
                    }
                ]
            }
        }, {
            libelle: "Je ne sais pas/Peu importe",
            coche: {},
        }
    ]
}
