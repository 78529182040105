import React from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import "./Home.css";
import ChoisirSaContraceptionLink from "../components/ChoisirSaContraceptionLink";
import { Link } from "react-router-dom";
import QuestionnaireLink from "../components/QuestionnaireLink";

const Home: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Accueil</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="homepage ion-padding-horizontal ion-text-center">
        <h1 className="quattrocento-bold">
          Quelle est la meilleure contraception pour moi aujourd’hui ?
        </h1>
        <p>
          <span className="emph-danger">My choice</span> a pour but de vous
          aider dans votre choix. Pour mieux découvrir les différents moyens de
          contraception qui existent. Elle ne remplace pas un professionnel de
          santé mais est là pour vous guider avant ou après une consultation.
        </p>
        <p className="emph-danger">
          Pour télécharger l’application, allez dans les paramètres de votre
          navigateur et choisissez "ajouter à l’écran d’accueil".
        </p>
        <p>
          L’application contient un test anonyme destiné à vous aider dans le
          choix de votre contraception. Vous y trouverez des informations sur
          toutes les contraceptions existantes en France.
        </p>
        <p className="gagalin">
          Attention, cette application est créée dans le cadre d’une thèse de
          Médecine Générale. Votre point de vue compte énormément. Anonyme, en 5
          minutes, répondez au questionnaire de satisfaction pour m’aider à
          savoir si l’application vous a aidé ou pas.
          <QuestionnaireLink />
        </p>
        <h3>Bonne découverte de My Choice!</h3>
        <p>Camille Teyssier, interne en Médecine Générale</p>
        <p>
          Cette application est inspirée du site <ChoisirSaContraceptionLink />.{" "}
          <Link to="/apropos">(voir à propos)</Link>
        </p>
        <p style={{ marginBottom: "150px" }} />
      </IonContent>
    </IonPage>
  );
};

export default Home;
