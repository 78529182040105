import {
  IonCard,
  IonCardContent,
  IonImg,
  IonItem,
  IonLabel,
  IonThumbnail,
} from "@ionic/react";
import { isEmpty } from "lodash";
import React from "react";
import { ReponseType } from "../../actions/types";

type AnswerCardElementTypeProps = {
  reponse: ReponseType;
  onClick: any;
};

const AnswerCardElement: React.FC<AnswerCardElementTypeProps> = ({
  reponse,
  onClick,
}) => {
  const { moyen, avantages, inconvenients } = reponse;

  return (
    <IonCard onClick={() => onClick(reponse.moyen)}>
      <IonItem>
        <IonThumbnail slot="start">
          <IonImg src={moyen.img} />
        </IonThumbnail>
        <IonLabel className="ion-text-wrap">{moyen.titre}</IonLabel>
      </IonItem>
      <IonCardContent>
        {!isEmpty(avantages) && (
          <>
            <p>Avantages :</p>
            <ul>
              {avantages?.map((val, i) => (
                <li key={`avantage-${i}`}>{val}</li>
              ))}
            </ul>
          </>
        )}
        {!isEmpty(inconvenients) && (
          <>
            <p>Inconvénients :</p>
            <ul>
              {inconvenients?.map((val, i) => (
                <li key={`inconvenients-${i}`}>{val}</li>
              ))}
            </ul>
          </>
        )}
      </IonCardContent>
    </IonCard>
  );
};

export default AnswerCardElement;
