import { IonRouterLink } from "@ionic/react";
import React from "react";

const ChoisirSaContraceptionLink: React.FC = () => {
  return (
    <IonRouterLink href="https://questionsexualite.fr" target="_blank">
      {" "}
      questionsexualite.fr{" "}
    </IonRouterLink>
  );
};

export default ChoisirSaContraceptionLink;
