import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonMenuButton,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import al from "../assets/images/allaitement.png";
import bb from "../assets/images/biberon.png";
import "../components/moyenModal/moyens.css";
import Allaitement from "../components/moyens/Allaitement";
import Biberon from "../components/moyens/Biberon";
import useModal from "../hooks/useModal";

const ContraceptionAccouchement: React.FC = () => {
  const [firstModalShowing, showFirstModal, hideFirstModal] = useModal();
  const [secondModalShowing, showSecondModal, hideSecondModal] = useModal();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle >La contraception après l'accouchement</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding-horizontal">
        <p>
          <span className="moyen-emph-danger">
            Après un accouchement, on a souvent d’autres choses en tête que
            notre contraception ainsi le risque de grossesse non prévue est très
            important.
          </span>
        </p>
        <p>
          Quelles sont les contraceptions possibles après un accouchement ? Se
          renseigner pendant la grossesse rend plus facile le choix après
          l’accouchement.
        </p>
        <p>
          Retomber enceinte rapidement après un accouchement peut dans certains
          cas être{" "}
          <span className="moyen-emph">
            dangereux pour votre santé et celui du futur bébé
          </span>{" "}
          (accouchement par césarienne, problèmes de santé avant cette grossesse
          ou apparus pendant cette grossesse).
        </p>
        <p>
          <span className="moyen-emph">
            Si vous ne souhaitez pas d’autres enfants
          </span>
          ,{" "}
          <span className="moyen-emph-danger">
            la stérilisation à visée contraceptive
          </span>{" "}
          peut être aussi envisagée.
        </p>
        <p>
          <span className="moyen-emph-danger">
            Les méthodes naturelles sont très peu fiables
          </span>{" "}
          surtout en cette période de chamboulement hormonal où les cycles ne
          sont pas réguliers. Le risque de tomber enceinte peut être présent
          avant les premières règles après l’accouchement.
        </p>
        <IonGrid>
          <IonRow class="ion-align-items-center">
            <IonCol size="6">
              <IonCard onClick={showFirstModal}>
                <IonImg src={al} />
                <IonCardHeader>
                  <IonCardTitle>Si vous allaitez</IonCardTitle>
                </IonCardHeader>
              </IonCard>
              <IonModal
                isOpen={firstModalShowing}
                onWillDismiss={hideFirstModal}
              >
                <IonHeader>
                  <IonToolbar>
                    <IonButtons slot="start">
                      <IonButton onClick={hideFirstModal}>
                        <IonIcon icon="close" />
                      </IonButton>
                    </IonButtons>
                    <IonTitle>Si vous allaitez</IonTitle>
                  </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                  <Allaitement />
                </IonContent>
              </IonModal>
            </IonCol>
            <IonCol size="6">
              <IonCard onClick={showSecondModal}>
                <IonImg src={bb} />
                <IonCardHeader>
                  <IonCardTitle>Si vous n'allaitez pas</IonCardTitle>
                </IonCardHeader>
              </IonCard>
              <IonModal
                isOpen={secondModalShowing}
                onWillDismiss={hideSecondModal}
              >
                <IonHeader>
                  <IonToolbar>
                    <IonButtons slot="start">
                      <IonButton onClick={hideSecondModal}>
                        <IonIcon icon="close" />
                      </IonButton>
                    </IonButtons>
                    <IonTitle>Si vous n'allaitez pas</IonTitle>
                  </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                  <Biberon />
                </IonContent>
              </IonModal>
            </IonCol>
          </IonRow>
        </IonGrid>

        <div style={{ margin: "132px 0px" }} />
      </IonContent>
    </IonPage>
  );
};

export default ContraceptionAccouchement;
