import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'

function useModal(): [boolean, () => void, () => void] {
    const [showing, setShowing] = useState<boolean>(false)

    const show = useCallback(() => {
        setShowing(true)
    }, [setShowing])

    const hide = useCallback(() => {
        setShowing(false)
    }, [setShowing])

    const history = useHistory();

    useEffect(() => {
        if (showing) {
            history.push(history.location.pathname, {
                modal: true,
                desc: "fake state for our modal",
            });
        }
    }, [history, showing]);

    useEffect(() => {
        if (showing) {
            window.addEventListener("popstate", hide);
        } else {
            window.removeEventListener("popstate", hide);
        }
    }, [showing, hide]);

    return [showing, show, hide]
}

export default useModal