import { IonApp, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import Menu from "./components/menu/Menu";
import appPages from "./constants/pages";
import { QuestionsProvider } from "./contexts/contexts";
import Answers from "./pages/Answers";
import Apropos from "./pages/Apropos";
import Avis from "./pages/Avis";
import ContraceptionAccouchement from "./pages/ContraceptionAccouchement";
import ContraceptionUrgence from "./pages/ContraceptionUrgence";
import Home from "./pages/Home";
import Moyens from "./pages/Moyens";
import TestIntroduction from "./pages/TestIntroduction";
import TestSlides from "./pages/TestPage";
import "./theme/base.css";
import "./theme/variables.css";

const App: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <QuestionsProvider>
          <IonSplitPane contentId="main">
            <Menu />
            <IonRouterOutlet id="main">
              <Route path={appPages.accueil.url} component={Home} exact />
              <Route
                path={appPages.testIntroduction.url}
                component={TestIntroduction}
                exact
              />
              <Route path={appPages.test.url} component={TestSlides} exact />
              <Route path={appPages.moyens.url} component={Moyens} exact />
              <Route path={appPages.reponses.url} component={Answers} exact />
              <Route path={appPages.apropos.url} component={Apropos} exact />
              <Route path={appPages.avis.url} component={Avis} exact />
              <Route
                path={appPages.contraceptionUrgence.url}
                component={ContraceptionUrgence}
                exact
              />
              <Route
                path={appPages.contraceptionAccouchement.url}
                component={ContraceptionAccouchement}
                exact
              />
              <Redirect from="/" to={appPages.accueil.url} exact />
            </IonRouterOutlet>
          </IonSplitPane>
        </QuestionsProvider>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
