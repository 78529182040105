import { Moyen } from '../../constants/moyen'; import { QuestionType } from '../questions';

export const question2: QuestionType = {
    id: 1,
    type: "radio",
    question: "Durant votre vie, souhaitez vous avoir des enfants ou d’autres enfants ?",
    options: [
        {
            libelle: "Oui",
            coche: {
                inconvenients: [
                    {
                        moyens: [Moyen.STERILISATION],
                        phrase: "Vous souhaitez avoir des enfants ou d’autres enfants"
                    }
                ]
            },
        },
        {
            libelle: "Non",
            coche: {
                avantages: [
                    {
                        moyens: [Moyen.STERILISATION],
                        phrase: "Vous ne souhaitez pas avoir d’enfants ou d’autres enfants"
                    }
                ]
            }
        },
        {
            libelle: "Je ne sais pas",
            coche: {
                inconvenients: [
                    {
                        moyens: [Moyen.STERILISATION],
                        phrase: "Vous ne savez pas si vous souhaitez d’autres enfants"
                    }
                ]
            }
        },
    ]
}