import { IonRouterLink } from "@ionic/react";
import React from "react";
import appPages from "../../constants/pages";

const PilluleO: React.FC = () => (
  <>
    <p className="moyen-section">Présentation</p>
    <p>
      Cette pilule contient 2 hormones : l'
      <span className="moyen-emph">oestrogène</span> et la{" "}
      <span className="moyen-emph">progestérone</span>. Ces hormones ressemblent
      à celles que fabrique naturellement le corps.
    </p>
    <p>Sous forme de plaquette :</p>
    <ul>
      <li>
        de <span className="moyen-emph">21</span> comprimés{" "}
        <span className="moyen-emph">avec une pause</span> de 7 jours entre 2
        plaquettes
      </li>
      <li>
        de <span className="moyen-emph">28</span> comprimés{" "}
        <span className="moyen-emph">sans pause</span> entre 2 plaquettes (4 ou
        7 comprimés placebo, c'est-à-dire ne contenant aucune hormone).
      </li>
    </ul>
    <p className="moyen-section">Efficacité</p>
    <p>
      Quand elle est bien prise, elle est supérieure à{" "}
      <span className="moyen-emph">99.7%</span>.
    </p>
    <p>Elle peut ne pas être efficace en cas :</p>
    <ul className="moyen-emph">
      <li>d'oubli</li>
      <li>de vomissement</li>
      <li>de diarrhée</li>
      <li>de prise d'autres médicaments.</li>
    </ul>
    <p>
      Elle est efficace{" "}
      <span className="moyen-emph">dès le début de la prise</span> si elle est
      débutée le premier jour des règles, sinon ce sera{" "}
      <span className="moyen-emph"> au bout de 7 jours.</span>
    </p>
    <p className="moyen-danger">
      Il faut être prête à y penser tous les jours pour qu’elle soit efficace.
    </p>
    <p className="moyen-section">Comment l'obtenir</p>
    <p>
      Elle nécessite une <span className="moyen-emph">prescription</span> par un
      médecin ou une sage-femme. Certaines sont{" "}
      <span className="moyen-emph">remboursées à 65%</span> et le coût peut
      varier de 2 euros à 14 euros par mois.
    </p>
    <p>
      Jusqu’à <span className="moyen-emph">26 ans</span>, certaines pilules sont
      délivrées{" "}
      <span className="moyen-emph">sans avance de frais et anonymement</span> :
      il n’est pas nécessaire de payer pour les obtenir et aucun autre
      justificatif qu’une ordonnance médicale n’est nécessaire.
    </p>
    <p>
      Elles peuvent aussi être obtenues dans les{" "}
      <span className="moyen-emph">centres de santé sexuelle</span> pour les
      mineures et les non-assurées sociales.
    </p>
    <p className="moyen-section">Les points positifs</p>
    <p>Elle permet de:</p>
    <ul className="moyen-emph">
      <li>régulariser les cycles</li>
      <li>diminuer les douleurs de règles</li>
      <li>diminuer le flux des règles</li>
      <li>diminuer l'acné pour certaines.</li>
    </ul>
    <p className="moyen-section">Les points négatifs</p>
    <p>Elle peut parfois:</p>
    <ul className="moyen-emph">
      <li>augmenter l'appétit</li>
      <li>donner des sautes d'humeur</li>
      <li>modifier le désir sexuel</li>
      <li>donner des douleurs dans les seins</li>
      <li>maux de tête</li>
      <li>augmenter l’acné pour certaines</li>
    </ul>
    <p>
      L’utilisation de cette pilule augmente le risque de thrombose (bouchon
      dans les vaisseaux). Ce risque augmente d'autant plus{" "}
      <span className="moyen-emph">si vous fumez</span>.
    </p>
    <p>
      Elle{" "}
      <span className="moyen-emph">
        ne peut pas être prise par tout le monde
      </span>{" "}
      et certains médicaments pris tous les jours peuvent diminuer son
      efficacité (en parler avec un professionnel de santé)
    </p>
    <p className="moyen-section">En cas d'oubli</p>
    <p>
      <span className="moyen-emph">
        Si vous avez oublié votre pilule, il y a moins de 12h
      </span>{" "}
      (en comptant à partir de l’heure à laquelle vous auriez dû la prendre) :
      prendre le comprimé oublié et continuer la plaquette normalement. On reste
      protégé.
    </p>
    <p>
      <span className="moyen-emph">
        Si vous avez oublié votre pilule, il y a plus de 12h
      </span>{" "}
      (en comptant à partir de l’heure à laquelle vous auriez dû la prendre) :
      prendre la pilule oubliée et prendre le prochain comprimé à l’heure
      habituelle ( 2 comprimés peuvent être pris le même jour). <br />
      <span className="moyen-color">
        Dans ce cas, vous n’êtes plus protégée
      </span>{" "}
      donc il faudra pendant les 7 jours suivant l’oubli, utiliser une
      contraception mécaniques (les préservatifs).
    </p>
    <p>
      <span className="moyen-color">
        Si l’oubli concerne 1 des 7 derniers comprimés
      </span>
      , sans compter les comprimés blancs (placebo) pour les plaquettes de 28
      comprimés, il faut :
    </p>
    <ul>
      <li>continuer la plaquette</li>
      <li>
        jeter les comprimés blancs (placebo) sur les plaquettes de 28 comprimés
      </li>
      <li>
        enchaîner directement avec une nouvelle plaquette (il est possible que
        vous n’ayez pas vos règles)
      </li>
    </ul>
    <p>
      <span className="moyen-color">
        Si vous avez eu un rapport sexuel dans les 5 jours précédant l’oubli
      </span>
      , il faut prendre une contraception d’urgence. (Voir{" "}
      <IonRouterLink href={appPages.contraceptionUrgence.url}>
        "La contraception d’urgence"
      </IonRouterLink>
      )
    </p>
  </>
);

export default PilluleO;
