import { IonRouterLink } from "@ionic/react";
import React from "react";
import { QUESTIONNAIRE_URL } from "../constants/url";

const QuestionnaireLink: React.FC = () => {
  return (
    <IonRouterLink href={QUESTIONNAIRE_URL} target="_blank">
      {" "}
      Questionnaire de satisfaction{" "}
    </IonRouterLink>
  );
};

export default QuestionnaireLink;
