
import { Moyen } from '../../constants/moyen'; import { QuestionType } from '../questions';

export const question4: QuestionType = {
    id: 3,
    type: "radio",
    question: "Avez-vous eu votre enfant il y a moins de 6 semaines ?",
    options: [
        {
            libelle: "Oui",
            coche: {
                inconvenients: [
                    {
                        moyens: [Moyen.PATCH, Moyen.STERILET_CUIVRE, Moyen.STERILET_HORMONAL, Moyen.ANNEAU, Moyen.PILULE_O],
                        phrase: "Non recommandé les 6 premières semaines après la grossesse",
                    }
                ],
                avantages: [
                    {
                        moyens: [Moyen.PILULE_M, Moyen.IMPLANT, Moyen.PRESERVATIF_F, Moyen.PRESERVATIF_M],
                        phrase: "Possible rapidement après l’accouchement",
                    }
                ]
            }
        }, {
            libelle: "Non",
            coche: {
                goTo: {
                    questionIndex: 5
                }
            }
        }
    ]
}