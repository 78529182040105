
import { Moyen } from '../../constants/moyen'; import { QuestionType } from '../questions';

export const question9: QuestionType = {
    id: 8,
    type: "radio",
    question: "Avez-vous des saignements entre vos règles ou des règles irrégulières lorsque vous n’utilisez pas de contraception ?",
    options: [
        {
            libelle: "Oui",
            coche: {
                avantages: [
                    {
                        moyens: [Moyen.PILULE_M, Moyen.PILULE_O, Moyen.PATCH, Moyen.ANNEAU, Moyen.IMPLANT, Moyen.STERILET_HORMONAL],
                        phrase: "Peut régulariser les règles",
                    }
                ],
                inconvenients: [
                    {
                        moyens: [Moyen.NATURELLES],
                        phrase: "Infaisable ou difficilement réalisable avec des cycles irréguliers",
                    }, {
                        moyens: [Moyen.PRESERVATIF_F, Moyen.PRESERVATIF_M, Moyen.CAPE, Moyen.DIAPHRAGME, Moyen.STERILET_CUIVRE, Moyen.STERILISATION],
                        phrase: "N'a pas d'impact sur des règles irrégulières ",
                    }
                ]
            }
        }, {
            libelle: "Non",
            coche: {
            }
        }
    ]
}
