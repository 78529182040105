
import { Moyen } from '../../constants/moyen'; import { QuestionType } from '../questions';

export const question13: QuestionType = {
    id: 12,
    type: "radio",
    question: "Etes-vous prête à utiliser une contraception non remboursée par la sécurité sociale ?",
    options: [
        {
            libelle: "Oui",
            coche: {
            }
        }, {
            libelle: "Non",
            coche: {
                avantages: [
                    {
                        moyens: [Moyen.PILULE_O, Moyen.PILULE_M, Moyen.PRESERVATIF_M],
                        phrase: "Une partie est remboursée par la sécurité sociale",
                    },
                    {
                        moyens: [Moyen.IMPLANT, Moyen.STERILET_CUIVRE, Moyen.STERILET_HORMONAL, Moyen.STERILISATION],
                        phrase: "Méthode remboursée par la sécurité sociale",
                    },
                ],
                inconvenients: [
                    {
                        moyens: [Moyen.PATCH, Moyen.ANNEAU, Moyen.PRESERVATIF_F, Moyen.CAPE, Moyen.DIAPHRAGME],
                        phrase: "Contraception non remboursée par la sécurité sociale ",
                    }
                ]
            }
        }, {
            libelle: "Je ne sais pas/Peu m'importe",
            coche: {
            }
        },
    ]
}
