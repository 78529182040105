
import { Moyen } from '../../constants/moyen'; import { QuestionType } from '../questions';

export const question8: QuestionType = {
    id: 7,
    type: "radio",
    question: "Avez-vous des règles douloureuses ou abondantes quand vous n’utilisez pas de contraception ?  ",
    options: [
        {
            libelle: "Oui",
            coche: {
                avantages: [
                    {
                        moyens: [Moyen.PILULE_M, Moyen.PILULE_O, Moyen.ANNEAU, Moyen.PATCH, Moyen.IMPLANT, Moyen.STERILET_HORMONAL],
                        phrase: "Peut diminuer la douleur et la quantité des règles ",
                    }
                ],
                inconvenients: [
                    {
                        moyens: [Moyen.STERILET_CUIVRE],
                        phrase: "Peut augmenter les douleurs ou la quantité des règles",
                    }, {
                        moyens: [Moyen.PRESERVATIF_M, Moyen.PRESERVATIF_F, Moyen.CAPE, Moyen.DIAPHRAGME, Moyen.NATURELLES, Moyen.STERILISATION],
                        phrase: "N'améliore pas les douleurs ou la quantité des règles",
                    }
                ]
            }
        }, {
            libelle: "Non",
            coche: {
            }
        }, {
            libelle: "Je ne sais pas",
            coche: {
            }
        }
    ]
}
