import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import QuestionnaireLink from "../components/QuestionnaireLink";

const Avis: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Votre avis</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="homepage ion-padding-horizontal">
        <p>
          Cette application est créée dans le cadre d’une thèse de Médecine
          Générale. Votre avis est très important. En répondant au questionnaire
          de satisfaction, vous m’aiderez énormément. Il est anonyme et prend
          environ 5 minutes.
          <QuestionnaireLink />
        </p>
      </IonContent>
    </IonPage>
  );
};

export default Avis;
